import React from 'react';
import './table.employee.mobile.scss';

const TableEmployeeMobile = ({ rows = [], className="", action = false, selectedRowID = false }) => {

  return (

    <section className = {`table-employee__mobile ${ className }`}>

       { rows.map(( row, key ) => (

        <div 
        
          key={ key }
          onClick={ () => action( row?.id )}
          className="table-employee__mobile__row flex flex-col"
          
        >

          <div className="table-employee__mobile__row__names">Фамилия и инициалы</div>
          <div className="table-employee__mobile__row__fcs">{ row.fio }</div>

          <div className="table-employee__mobile__row__typevalue flex">

            <div className="table-employee__mobile__row__typevalue__first">

              <div className="table-employee__mobile__row__types">БЛК</div>
              <div className="table-employee__mobile__row__values">{ row.count }</div>

            </div>

            <div className="table-employee__mobile__row__typevalue__second">

              <div className="table-employee__mobile__row__types">Дата рег.</div>
              <div className="table-employee__mobile__row__values">{ row.reg_date }</div>

            </div>

            <div className="table-employee__mobile__row__typevalue__third">

              <div className="table-employee__mobile__row__types">Дата авт.</div>
              <div className="table-employee__mobile__row__values">{ row.auth_date }</div>

            </div>

          </div>

        </div>

      ))}

    </section>

  )

}

export default TableEmployeeMobile;
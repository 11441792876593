import React from 'react';

import './togglechecker.scss';


const ToggleChecker = ({ value = false, set, text = "", text_reverse = {text}, title = "", anchorPosition = "left" }) => {

  return (

    <div className={`togglechecker ${ value ? "togglechecker--checked" : "" }`}>

      { title !== "" && <div className="togglechecker__title">{ title } </div> }

      <div 
      
        onClick = { () => { set( !value ) } }
        className={`togglechecker__container flex items-center ${ anchorPosition !== "left" ? "flex-row-reverse" : "" }`}
        
      >

        <div className="togglechecker__anchor">

          <div className="togglechecker__anchor__dot" />

        </div>

        <div className="togglechecker__text"> { text } </div>

      </div>

    </div>

  );

}

export default ToggleChecker;
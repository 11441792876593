import { post } from 'axios';
import cookie from 'react-cookies';
import { API } from '../../settings/api';

export async function In( store, mail, pass, redirect, showError ) {

  try {

    const { status, data } = await post( API.sign.in, { email: mail, password: pass } );

    console.log(`signIn data:`, data);

    if ( status === 200 ) {

      if ( data.success ) {

        cookie.save('token', data.token, { path: '/' });
        redirect();
        
      } else {

        throw( data.text );

      }

    } else {

      throw('Нет соединения с сервером');

    }

  } catch ( err ) {

    console.error( err );
    showError( err );

  }
  
}

export async function Check( store, token ) {

  try {

    const { status, data } = await post( API.sign.check, { token: token } );

    console.log(`check data: ${status}`, data);

    if ( status === 200 && data.success ) {

      console.log(`data success data`, data );

      if ( +data?.user?.role !== 1 ) { throw('В доступе отказано!'); }

      store.setState({ 
        
        user_name: data.user.name,
        user_role: data.user.role,
      
      });
      return true;       
      
    } else {

      throw('');

    }

  } catch ( err ) {

    alert(`В доступе отказано!`);
    console.error(`Неверный токен`);
    store.actions.sign.Out();
    return false;    

  }
  
}

export async function Out() {

  try {

    cookie.remove(`token`);
    window.location = "/sign";

  } catch(err) {

  }

}
import React, { Fragment } from 'react';
import useGlobal from '../../../store';

const ImageLoader = ({ children, setProgress, setUrl }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { images } = globalActions;

  const uniq = Math.trunc( Math.random( 0, 1 ) * 1000 );

  const handleLoad = async ( e ) => {

    e.preventDefault();

    const newFiles = e.target.files;

    let readers = []; 
    let readers_count = 0;

    Array.from(newFiles).forEach(( file, index ) => {
        
      readers_count++;
      readers[ index ] = new FileReader();
      readers[ index ].readAsDataURL(file);
      readers[ index ].onloadend = async () => {

        readers_count--;

        if ( readers_count === 0 ) {

          const url = await images.upload( e, newFiles, setProgress );
          url && setUrl( url );
        
        }
          
      };

    });    

  }

  return (

    <Fragment>

      <input

        type = "file" 
        multiple = { false } 
        name = "image" 
        id = {`photo-upload-${uniq}`}
        onChange = { handleLoad } 
        accept = "image/jpeg,image/jpg,image/png,image/gif"

      />

      <label htmlFor = {`photo-upload-${uniq}`}>
        
        { children }

      </label>

    </Fragment>

  );

}

export default ImageLoader;
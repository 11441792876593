import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';

import { ReactComponent as IconGeo } from './card-geo.svg';
import { ReactComponent as IconMeasure } from './card-measures.svg';
import { ReactComponent as IconOrder } from './card-orders.svg';
import { ReactComponent as IconPeople } from './card-people.svg';
import { ReactComponent as IconPhone } from './card-phone.svg';

import './table.card.scss';
import Button from '../button';

const TableCard = ( props ) => {

  const   {
    
    action,
    city = "",
    date = "",
    measures = 0,
    orders = 0,
    people = 0,
    phone = 0, 
    company = "",
    allnames = ""
  
  } = props;

  return (

    <div className="table-card">

      <div className="table-card__header flex">

        <IconGeo className="icon-geo" />
        <p className="table-card__header__city">{ city }</p>

        <p className="table-card__header__date">{ date }</p>

        <div className="table-card__header__liner"></div>

        <IconMeasure className="icon-measures" />
        <p className="table-card__header__measures">{ measures }</p>

        <div className="table-card__header__liner" />

        <IconOrder className="icon-orders" />
        <p className="table-card__header__orders">{ orders }</p>

        <div className="table-card__header__liner" />

        <IconPeople className="icon-people" />
        <p className="table-card__header__people">{ people }</p>

      </div>

      <p className="table-card__company">{ company }</p>
      <p className="table-card__allnames">{ allnames }</p>

      <div className="table-card__footer flex relative">

        <img 
        
          src="/images/icons/ph_phone.png" 
          className="icon-phone" 
          onClick={ () => { window.open(`tel:${ phone }`) }}
          
        />
        
        <div className="table-card__footer__liner" />
        <a href={`tel:${ phone }`} className="table-card__footer__phone">{ phone }</a>

        <Button 
        
          action = { action }
          text="подробнее"
          className="table-card__footer__button"
        
        />

      </div>

    </div>

  );

 }

export default TableCard;
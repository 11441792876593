import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Site } from "./images/site.svg";
import { ReactComponent as Companies } from "./images/companies.svg";
import { ReactComponent as Materials } from "./images/materials.svg";
import { ReactComponent as Back } from "./images/back.svg";
import { ReactComponent as Info } from "./images/info.svg";
import { ReactComponent as Team } from "./images/team.svg";
import { ReactComponent as Price } from "./images/price.svg";

import "./menu.mobile.scss";

const MenuMobile = ({ main = true, page = 1, section = 1, action = false }) => {

  const navigate = useNavigate();
  
  function scrollToTop() { 

    setTimeout(() => {
      
      window.scrollBy( 0, 0 );
      document.body.scrollTop = document.scrollingElement.scrollTop = 0;
      document.getElementById("root").scrollBy( 0, 0 );
    
    }, 100 );
    
  }

  return (

    <Fragment>

      { main

        ?

          <div className="menu-mobile flex items-center fixed mobile-flex">

            <div className={`menu-mobile__thumb absolute menu-mobile__thumb-${ page }`} />

            <div className="menu-mobile__item ml-69-m" onClick={ () => { navigate('/companies'); scrollToTop(); } }>

              <div className="menu-mobile__item__icon__container">
                <Companies className="menu-mobile__item__icon menu-mobile__item__icon__first" />
              </div>
              <p className="menu-mobile__item__text menu-mobile__item__text__green_main">Франшизы</p>

            </div>

            <div className="menu-mobile__item__liner" />

            <div className="menu-mobile__item" onClick={ () => { navigate('/site'); scrollToTop(); } }>

              <div className="menu-mobile__item__icon__container">
                <Site className="menu-mobile__item__icon menu-mobile__item__icon__second" />
              </div>
              <p className="menu-mobile__item__text menu-mobile__item__text__blue_main">Сайт</p>

            </div>

            {/* <div className="menu-mobile__item__liner" />

            <div className="menu-mobile__item" onClick={ () => { navigate('/materials'); scrollToTop(); } }>

              <div className="menu-mobile__item__icon__container">
                <Materials className="menu-mobile__item__icon menu-mobile__item__icon__third" />
              </div>
              <p className="menu-mobile__item__text menu-mobile__item__text__orange_main">Материалы</p>

            </div> */}

          </div>

        :

          <div className="menu-mobile flex items-center fixed mobile-flex">

          <div className={`menu-mobile__thumb absolute menu-mobile__thumb-${ section }`} />

          <div className="menu-mobile__item" onClick={ () => { navigate('/companies'); scrollToTop(); } }>

            <div className="menu-mobile__item__icon__container">
              <Back className="menu-mobile__item__icon menu-mobile__item__icon__one" />
            </div>
            <p className="menu-mobile__item__text">Назад</p>

          </div>

          <div className="menu-mobile__item__liner" />

          <div className="menu-mobile__item" onClick={ () => { action(11); scrollToTop(); }}>

            <div className="menu-mobile__item__icon__container">
              <Info className="menu-mobile__item__icon menu-mobile__item__icon__two" />
            </div>
            <p className="menu-mobile__item__text menu-mobile__item__text__orange">Инфо</p>

          </div>

          <div className="menu-mobile__item__liner" />

          <div className="menu-mobile__item" onClick={ () => { action(12); scrollToTop(); }}>

            <div className="menu-mobile__item__icon__container">
              <Price className="menu-mobile__item__icon menu-mobile__item__icon__three" />
            </div>
            <p className="menu-mobile__item__text menu-mobile__item__text__blue">Прайс</p>

          </div>

          <div className="menu-mobile__item__liner" />

          <div className="menu-mobile__item" onClick={ () => { action(13); scrollToTop(); }}>

            <div className="menu-mobile__item__icon__container">
              <Team className="menu-mobile__item__icon menu-mobile__item__icon__four" />
            </div>
            <p className="menu-mobile__item__text menu-mobile__item__text__green">Команда</p>

          </div>

        </div>

        }

    </Fragment>

  )

}

export default MenuMobile;
import { useState } from "react";

const daysShortArr = [ "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс" ];

const monthNamesArr = [

  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",

];

const useCalendar = (daysShort = daysShortArr, monthNames = monthNamesArr) => {

  const today = new Date();

  const todayFormatted = `${ today.getDate() }-${ today.getMonth() + 1 }-${ today.getFullYear() }`;
  const daysInWeek = [ 1, 2, 3, 4, 5, 6, 0 ];

  const [selectedDate, setSelectedDate] = useState( today );

  const selectedMonthLastDate = new Date(

    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0

  );

  const prevMonthLastDate = new Date(

    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    0

  );

  const daysInMonth = selectedMonthLastDate.getDate();

  const firstDayInMonth = new Date(
    
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
    
  ).getDay();
  
  const startingPoint = daysInWeek.indexOf(firstDayInMonth) + 1;

  let prevMonthStartingPoint = prevMonthLastDate.getDate() - daysInWeek.indexOf(firstDayInMonth) + 1;
  let currentMonthCounter = 1;
  let nextMonthCounter = 1;

  const rows = 6;
  const cols = 7;
  const calendarRows = {};

  for ( let i = 1; i < rows + 1; i++ ) {

    for ( let j = 1; j < cols + 1; j++ ) {

      if ( !calendarRows[i] ) { calendarRows[i] = []; }

      if ( i === 1 ) {

        if ( j < startingPoint ) {

          calendarRows[i] = [

            ...calendarRows[i],
            {

              classes: "in-prev-month",
              date: `${prevMonthStartingPoint}-${ 

                selectedDate.getMonth() === 0 

                  ? 12 
                  : selectedDate.getMonth() }-${ selectedDate.getMonth() === 0

                    ? selectedDate.getFullYear() - 1
                    : selectedDate.getFullYear()

              }`,

              value: prevMonthStartingPoint,

            },

          ];

          prevMonthStartingPoint++;

        } else {

          calendarRows[i] = [

            ...calendarRows[i],
            {
              classes: "day",
              date: `${currentMonthCounter}-${ selectedDate.getMonth() + 1 }-${selectedDate.getFullYear()}`,
              value: currentMonthCounter,
            },

          ];

          currentMonthCounter++;

        }

      } else if ( i > 1 && currentMonthCounter < daysInMonth + 1 ) {

        calendarRows[i] = [

          ...calendarRows[i],
          {
            classes: "day",
            date: `${currentMonthCounter}-${ selectedDate.getMonth() + 1 }-${selectedDate.getFullYear()}`,
            value: currentMonthCounter,
          },

        ];

        currentMonthCounter++;

      } else {

        calendarRows[i] = [

          ...calendarRows[i],

          {
            classes: "in-next-month",
            date: `${nextMonthCounter}-${

              selectedDate.getMonth() + 2 === 13
                ? 1
                : selectedDate.getMonth() + 2

            }-${

              selectedDate.getMonth() + 2 === 13
                ? selectedDate.getFullYear() + 1
                : selectedDate.getFullYear()

            }`,

            value: nextMonthCounter,

          },

        ];

        nextMonthCounter++;

      }

    }

  }

  const getPrevYear = () => {

    setSelectedDate(

      prevValue => new Date( 
        
        prevValue.getFullYear() - 1, 
        selectedDate.getMonth(), 
        1 
        
      )

    );

  }

  const getNextYear = () => {

    setSelectedDate(

      prevValue => new Date( 
        
        prevValue.getFullYear() + 1, 
        selectedDate.getMonth(), 
        1 
        
      )

    );

  }

  const getPrevMonth = () => {

    setSelectedDate(
      
      prevValue => new Date(
        
        prevValue.getFullYear(),
        prevValue.getMonth() === 0 ? 11 : selectedDate.getMonth() - 1,
        1
        
      )
      
    );

  }

  const getNextMonth = () => {

    setSelectedDate(
      
      prevValue => new Date(

        prevValue.getFullYear(),
        prevValue.getMonth() === 11 ? 0 : selectedDate.getMonth() + 1,
        1

      )

    );

  }

  const convertDateToDays = ( date ) => {

    const a = date.split('.');
    const seconds = (Number(a[0]) + Number(a[1]) * 30 + Number(a[2]) * 365);
    return Number(seconds);

  }

  return {

    daysShort,
    monthNames,
    todayFormatted,
    calendarRows,
    selectedDate,
    getPrevMonth,
    getNextMonth,
    getPrevYear,
    getNextYear,
    convertDateToDays

  }

}

export default useCalendar;
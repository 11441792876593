import React from 'react';

import Header from '../header';

import './container.scss';


const Container = ({ children, className = ""}) => {

  return (
  
    <div className="container flex flex-col flex-1" draggable="false">
        
      <Header />

      <div className={`container__content flex-1 ${ className }`} draggable="false">

        { children }

      </div>

    </div>
  
  );
  
}
  
export default Container;
import React, { useState, useEffect, Fragment } from "react";
import twoDigitFormat from "../../../scripts/helpers/two.digit.format";
import cssIf from "../../../scripts/helpers/class.add.if";

import DateChoice from "./date.choice";
import CalendarTable from "./calendar.table";
import useCalendar from "./use.calendar";

import "./datepicker.scss";

const DatePicker = (props) => {

  const {

    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isActive

  } = props;

  const {

    calendarRows,
    selectedDate,
    daysShort,
    monthNames,
    getNextMonth,
    getPrevMonth,
    getPrevYear,
    getNextYear,
    convertDateToDays

  } = useCalendar();

  const [ choiceType, setchoiceType ] = useState("start");

  const [ hover, setHover ] = useState(1);
  const [ showCalendar, setShowCalendar ] = useState( false );

  useEffect(() => {

    if (props.start || props.end) {

      setStartDate(props.start);
      setEndDate(props.end);

    }

  }, [ props.start, props.end ]);

  useEffect(() => {

    if ( endDate ) { setShowCalendar(false); }

  }, [ endDate ]);

  const dateClickHandler = ( dates ) => {

    const date = `${ twoDigitFormat( dates.split(".")[ 0 ] ) }.${ twoDigitFormat( dates.split(".")[ 1 ] ) }.${ dates.split(".")[ 2 ] }`;
    
    if (startDate && endDate) {

      setStartDate(null);
      setEndDate(null);
      setStartDate(date);
      return setchoiceType("end");

    }

    if (startDate && date < Number(startDate)) {
      
      setStartDate(date);
      return setchoiceType("end");

    }

    if (endDate && date > endDate) {

      setEndDate(date);
      return setchoiceType("end");

    }

    if (choiceType === "start") {

      setStartDate(date);
      return setchoiceType("end");

    } else {

      if (convertDateToDays(date) < convertDateToDays(startDate)){

        setStartDate(date);
        setEndDate(startDate);

      } else {

        console.log(convertDateToDays(startDate), endDate, convertDateToDays(date), convertDateToDays(date) < convertDateToDays(startDate))
        setEndDate(date);

      }

    }

  }

  function checkBetween( date ) {

    let _date;
    let _start;

    if ( date.indexOf("-") !== -1 ) {

      _date = {
        d: parseInt( date.split("-")[0] ),
        m: parseInt( date.split("-")[1] ),
        y: parseInt( date.split("-")[2] )
      }

    } else {

      _date = {
        d: parseInt( date.split(".")[0] ),
        m: parseInt( date.split(".")[1] ),
        y: parseInt( date.split(".")[2] )
      }

    }

    if ( startDate.indexOf("-") !== -1 ) {

      _start = {
        d: parseInt( startDate.split("-")[0] ),
        m: parseInt( startDate.split("-")[1] ),
        y: parseInt( startDate.split("-")[2] )
      }

    } else {

      _start = {
        d: parseInt( startDate.split(".")[0] ),
        m: parseInt( startDate.split(".")[1] ),
        y: parseInt( startDate.split(".")[2] )
      }

    }

    let result = false;

    if ( startDate && !endDate ) {

      if (

        (
          ( 
            _date.d >= _start.d
            && 
            _date.m === _start.m
            && 
            _date.y === _start.y
          )
          || 
          ( 
            _date.m > _start.m
            &&
            _date.y === _start.y
          )
          || 
          ( 
            _date.y > _start.y
          )

        ) && _date.d <= hover

      ) { result = true; }
      
    } else {

      let _end;

      if ( endDate.indexOf("-") !== -1 ) {

        _end = {
          d: parseInt( endDate.split("-")[0] ),
          m: parseInt( endDate.split("-")[1] ),
          y: parseInt( endDate.split("-")[2] )
        }
  
      } else {
  
        _end = {
          d: parseInt( endDate.split(".")[0] ),
          m: parseInt( endDate.split(".")[1] ),
          y: parseInt( endDate.split(".")[2] )
        }
  
      }
      
      if (

        ( 
          ( 
            ( _date.d >= _start.d ) 
            && 
            _date.m === _start.m 
            && 
            _date.y === _start.y 
          ) 
          && 
          ( 
            _date.d <= _end.d 
            && 
            _date.m === _end.m 
            && 
            _date.y === _end.y 
            
          ) 
        )
        || 
        ( 
          ( 
            _date.y === _start.y 
            && 
            ( 
              _date.m > _start.m 
              || 
              ( 
                _date.m === _start.m 
                && 
                _date.d >= _start.d 
              ) 
            ) 
          ) 
          && 
          (
            ( 
              _date.m === _end.m 
              && 
              _date.d <= _end.d 
            ) 
            ||
            ( 
              _date.m < _end.m 
              && 
              _date.y === _end.y 
            ) 
          ) 
        )
        || 
        ( 
          ( _date.y > _start.y ) 
          && 
          ( 
            ( 
              _date.y === _end.y 
              && 
              ( 
                ( _date.m < _end.m ) 
                || 
                ( 
                  _date.m === _end.m 
                  &&
                  _date.d <= _end.d 
                ) 
              ) 
            ) 
            || 
            ( _date.y < _end.y ) 
          ) 
        )

      ) { 
        
        result = true; 
      
      }

    }

    return result;

  }

  return (

    <div className = {`datepicker ${ cssIf( isActive, `datepicker--active` ) }`}>

      <div
        className={`panel-button-select ${ isActive && "panel-button-select--active" }`}
        onClick={() => setShowCalendar( !showCalendar ) }
      >
        
        { !isActive 
        
          ? <div>выбрать период</div>
          
          : <Fragment>

              <div onClick={() => setchoiceType("start")}>

              { startDate }

              </div>

              {( endDate && ( startDate !== endDate )) && 

              <Fragment>

                <div className="desktop">{"-"}</div>

                <div onClick={() => setchoiceType("end")}>
                  {endDate}
                </div>

              </Fragment>

              }
            
            </Fragment> 
            
        }

      </div>


      { showCalendar &&

        <div className="calendar">

          <DateChoice

            selectedDate = { selectedDate }
            getPrevMonth = { getPrevMonth }
            getNextMonth = { getNextMonth }
            getPrevYear  = { getPrevYear }
            getNextYear  = { getNextYear }
            monthNames   = { monthNames }

          />

          <CalendarTable

            daysShort    = { daysShort }
            startDate    = { startDate }
            endDate      = { endDate }
            calendarRows = { calendarRows }
            setHover     = { setHover }
            checkBetween = { checkBetween }
            dateClickHandler = { dateClickHandler }

          />

        </div>
        
      }

    </div>

  );

}

export default DatePicker;
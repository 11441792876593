import React from 'react';
import ReactDOM from 'react-dom/client';

import AppRouter from './react/pages/router';
import reportWebVitals from './reportWebVitals';

import './styles/global.scss';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>

);

reportWebVitals();
import React, { useState, useRef } from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import numberSpaces from '../../../scripts/helpers/numbers.with.spaces';
import './checkitem.scss';

const CheckItem = ( props ) => {

  const input = useRef( null );
  const inputPerJob = useRef( null );
  const [ showPriceInput, setShowPriceInput ] = useState( false );
  const [ showPricePerJobInput, setShowPricePerJobInput ] = useState( false );
  const [ price, setPrice ] = useState( props.price );
  const [ pricePerJob, setPricePerJob ] = useState( ( props?.pricePerJob && props.pricePerJob !== null && props.pricePerJob !== "" ) ? props.pricePerJob : 0 );

  const hidePriceInput = () => setShowPriceInput( false );
  const hidePricePerJobInput = () => setShowPricePerJobInput( false );

  function savePrice() {

    props.action( price, pricePerJob );
    hidePriceInput();
    hidePricePerJobInput();

  }

  return (

    <div className = "checkitem relative flex row">

      <p className = "checkitembox__costtitle checkitem__name">{ props.text }</p>

      <div className = "checkitem__price checkitem__price__stuff relative flex row">

        { showPriceInput === true 
          
          ? <div className = "absolute checkitembox checkitembox--price checkitembox__costtitle__input">

              <p className="checkitembox__costtitle__text mobile">За материалы:</p>

              <input 
              
                ref = { input }
                type = "number" 
                className = "checkitem__costtitle checkitem__input absolute text-right" 
                // value={ ( price || price?.toString() === "0" ) ? price : pricePerJob }
                value = { price }
                onBlur = { savePrice }
                onChange = {(e) => {

                  setPrice( e.target.value ) 
                  if ( +props.price === +props.pricePerJob ) { setPricePerJob( e.target.value ) }

                }}
                onKeyUp = {(e) => { 

                  if ( e.key === "Enter" ) {
                    
                    savePrice();

                  } else if ( e.key === "Escape" ) { 

                    if ( price !== props.price && window.confirm(`Отменить изменение цены?`) ) {

                      setPrice( props.price ); 
                      setPricePerJob( props.pricePerJob ); 

                    }

                    hidePriceInput();

                  }

                }}
                
              />

            </div>      
        
          : <div 
          
              className = "absolute checkitembox checkitembox--price checkitembox__costtitle text-right"
              onClick={() => { 
                
                if ( price === null ) { return; }

                setShowPriceInput(true);
                setTimeout( () => {

                  input.current.focus()

                }, 100 );
              
              }}
              
            >

              <p className="checkitembox__costtitle__text mobile">За материалы:</p>
              <p className = "absolute checkitem__costtitle">{ numberSpaces( price ) }<span className="mobile">р{ cssIf( props?.unit && props?.unit !== "", `/${ props.unit }` ) }</span></p>

            </div>

        }

        <div className = "absolute checkitembox checkitembox__subtitle">
      
          <p className = "checkitembox__costtitle checkitembox__costtitle__unit">р{ cssIf( props?.unit && props?.unit !== "", `/${ props.unit }` )}</p>

        </div>

      </div>

      <div className = "checkitem__price checkitem__price__work relative flex row">

        { showPricePerJobInput
        
          ? <div className = "absolute checkitembox checkitembox--price checkitembox__costtitle__input">

            <p className="checkitembox__costtitle__text mobile">За работу:</p>
            
            <input 
            
              ref = { inputPerJob }
              type = "number" 
              className = "checkitem__costtitle checkitem__input absolute text-right" 
              value = { pricePerJob }
              onBlur = { savePrice }
              onChange = {( e ) => { 
                
                setPricePerJob( e.target.value );
                if ( +props.price === +props.pricePerJob ) { setPrice( e.target.value ) }
              
              }}
              onKeyUp = {( e ) => { 

                if ( e.key === "Enter" ) {
                  
                  savePrice();

                } else if ( e.key === "Escape" ) { 

                  if ( price !== props.price && window.confirm(`Отменить изменение цены?`) ) {

                    setPricePerJob( props?.pricePerJob || 0 ); 
                    setPrice( props?.price || 0 ); 

                  }

                  hidePricePerJobInput();

                }

              }}
              
            />

          </div>      

          : <div 
        
            className = "absolute checkitembox checkitembox--price checkitembox__costtitle text-right"
            onClick={() => { 
              
              setShowPricePerJobInput(true);
              setTimeout( () => {

                inputPerJob.current.focus()

              }, 100 );
            
            }}
            
          >

            <p className="checkitembox__costtitle__text mobile">За работу:</p>
            <p className = "absolute checkitem__costtitle">{ pricePerJob === 0 ? '0' : numberSpaces( pricePerJob ) }<span className="mobile">р{ cssIf( props?.unit && props?.unit !== "", `/${ props.unit }` ) }</span></p>

          </div>

        }

        <div className = "absolute checkitembox checkitembox__subtitle">

          <p className = "checkitembox__costtitle checkitembox__costtitle__unit">р{ cssIf( props?.unit && props?.unit !== "", `/${ props.unit }` )}</p>

        </div>

      </div>

    </div>

  );

}

export default CheckItem;
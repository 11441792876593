import React from 'react';

import './listitem.scss';


const ListItem = ({ imgUrl = "", text = "", isActive = false, action = () => {} }) => {

  return (

    <div 
    
      className={ `listitem flex ${ isActive ? 'listitem--active' : '' }` } 
      onClick={ action }
      
    >
        
        <img src={ imgUrl } className="listitem__img" />
        <p className="listitem__text">{ text }</p>

    </div>

  );

}

export default ListItem;
const STATS_PERIOD = {

  ALL: 1,
  MONTH: 2,
  CUSTOM: 3

}

export {

  STATS_PERIOD

}
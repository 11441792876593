import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import cssIf from '../../../scripts/helpers/class.add.if';

import ToggleChecker from '../../components/togglechecker';
import EmployeeItem from '../../components/employeeitem';
import CheckItem from '../../components/checkitem';

import { ReactComponent as BackButton } from './img/back-button.svg';
import { ReactComponent as EditButton } from './img/edit-button.svg';
import { ReactComponent as AddButton } from './img/add-button.svg';

import './company.scss';

import CompanyInfoPopUp from '../../popups/company.info';
import CompanyEmployeePopUp from '../../popups/company.employee';
import CompanyGooglePopUp from '../../popups/company.google';
import CompanyYandexPopUp from '../../popups/company.yandex';
import useGlobal from '../../../store';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import Table from '../../components/table';
import PriceList from '../../components/pricelist';
import numberSpaces from '../../../scripts/helpers/numbers.with.spaces';
import PeriodBar from '../../components/period.bar';
import TableEmployeeMobile from '../../components/table.employee.mobile';
import MenuMobile from '../../components/menu-mobile';
import UniqMetric from '../../components/unic.metric';


const Company = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [ globalState, globalActions ] = useGlobal();

  const { company, employees, employee } = globalState;

  const { 
    
    google_analytics_key,
    yandex_metrics_key,
    google_analytics_default_key,
    yandex_metrics_default_key

  } = company;

  const { companies, changeStates } = globalActions;

  const EMPLOYEES_TABLE_SETTINGS = {

    head: [

      "Фамилия инициалы",
      "БЛК",
      "Дата рег.",
      "Дата авт."

    ],

    template: "260px 100px 100px 100px"

  }

  const [ CompanyPopup,  showCompanyPopup  ] = CompanyInfoPopUp();
  const [ EmployeePopup, showEmployeePopup ] = CompanyEmployeePopUp();
  const [ GooglePopup,   showGooglePopup   ] = CompanyGooglePopUp();
  const [ YandexPopup,   showYandexPopup   ] = CompanyYandexPopUp();

  const [ Dummy, reloadDummy ] = useReloadDummy();

  useEffect(() => {

    if ( !id || id === "" ) { window.location = "/companies"; }
    else { companies.get( id, reloadDummy ); console.log(`uEF companies.get id="${ id }"`); }    

  }, [ id ]);

  function handleSelectEmplyee( id ) {

    if ( employee.id === id ) {

      showEmployeePopup();

    } else {

      const obj = employees.find( el => +el.id === +id );

      if ( !obj ) { console.error(`handleSelectEmployee: не найден сотрудник с id:${ id }`); return; }

      changeStates({ 
        
        employee: {

          id:       obj.id,
          fullname: obj.fullname,
          phone:    obj.phone,
          email:    obj.email

        } 

      });

    }

  }

  const [ mobilePage, setMobilePage ] = useState(11);
  
  return (

    <Container className = "container__company relative">

      <MenuMobile main = { false } section = { mobilePage } action = { setMobilePage }/>

      <Dummy />

      { CompanyPopup }
     
      { EmployeePopup }

      { GooglePopup }

      { YandexPopup }

      <div className={`flex row company-header company-header-${ mobilePage }`} draggable="false">

        <BackButton 
        
          title = "Назад к таблице франшиз"
          className = "company-header__button desktop-flex"
          onClick = {() => {
            
            companies.clearCompanyStates();
            navigate('/companies') 
          
          }} 
          
        />

        <p className="montserrat font-semibold company__pagename">{ company.name }</p>
        
        <ToggleChecker
        
          value = { company.active }
          text = { company.active ? "Доступ активирован" : "Доступ закрыт" }
          set = { ( bool ) => { companies.changeInfo( company.id, { active: bool } ) }}
        
        />

        { !company.active &&
        
          <p 
          
            title = "Безвозвратно удалить франшизу и всё что с ней связано"
            className = "rmv_company_btn"
            onClick = { () => companies.remove( id ) }
            
          >удалить франшизу</p> 
          
        }

      </div>

      <div className={`company-columns company-columns--mobile-page-${ mobilePage }`} draggable="false">

        <div className="company-columns__info flex col" draggable="false">

          <div className="company-columns__common company-columns__information">

            <div className="company-columns__common__header flex row">

              <p className="montserrat semibold company-head company-head company-head-info">Информация</p>

              <EditButton 
              
                className="company__info__edit-button"
                onClick={ showCompanyPopup }
                
              />

            </div>

            <div className="company-columns__common__body flex">

              <div className="flex anal">
                <p className="montserrat semibold company-head graytext">Владелец:</p>
                <p className="montserrat semibold company-head">{ company.fio }</p>
              </div>

              <div className="flex anal">
                <p className="montserrat semibold company-head graytext">Адрес сайта:</p>
                <p className="montserrat semibold company-head">
                  <a href={`https://radivas-remont.ru/${ company.url }`} rel="norefferer noopener">https://radivas-remont.ru/{ company.url }</a>
                </p>
              </div>

              <div className="flex anal">
                <p className="montserrat semibold company-head graytext">Телефон:</p>
                <a href={`tel:${ company.phone }`} className="montserrat semibold company-head">{ company.phone }</a>
              </div>

              <div className="flex anal">
                <p className="montserrat semibold company-head graytext">Юр. адрес:</p>
                <p className="montserrat semibold company-head">{ company.address }</p>
              </div>

              <div className="flex anal">
                <p className="montserrat semibold company-head graytext">Дата регистрации:</p>
                <p className="montserrat semibold company-head">{ company.reg_date || "-" }</p>
              </div>

              <div className="flex anal">
                <p className="montserrat semibold company-head graytext">Комментарий</p>
                <p className="montserrat semibold company-head">{ company?.comment || "" }</p>
              </div>

            </div>

          </div>

          <div className="company-columns__common company-columns__analitycs">

            <div className="company-columns__common__header  company-columns__analitycs__header flex justify-between items-center row">

              <p className="montserrat semibold company-head company-head-info">Статистика</p>
              
              <div className="flex"><div className="company-head__periodtext">за:</div><PeriodBar company = { id } /></div>

            </div>

            <div className="flex justify-center anal-cont">
                  
              <div className="flex col justify-center item-center anal-subcont">

                <div className="flex col justify-center item-center">

                  <p className="montserrat semibold company-head company-head__major text-anal">Всего договоров на сумму:</p>
                  <p className="montserrat font-bold company-head company-head__top text-anal__subtext">{ company?.allProfit ? numberSpaces( company.allProfit ) : 0 }</p>

                </div>

                <div className="flex col justify-center item-center">

                  <p className="montserrat semibold company-head company-head__minor text-anal">Дата крайнего замера:</p>
                  <p className="montserrat font-bold company-head company-head__both text-anal__subtext text-anal__subtext_small">{ company.last_date || "-" }</p>

                </div>

              </div>

              <div className="flex col justify-center item-center anal-subcont">

                <div className="flex col justify-center item-center">

                  <p className="montserrat semibold company-head company-head__minor text-anal">Всего замеров:</p>
                  <p className="montserrat font-bold company-head company-head__middle text-anal__subtext">{ company.projects || 0 }</p>

                </div>

                <div className="flex col justify-center item-center">

                  <p className="montserrat semibold company-head company-head__minor text-anal">Всего договоров:</p>
                  <p className="montserrat font-bold company-head company-head__middle text-anal__subtext">{ company.contracts || 0 }</p>

                </div>

              </div>

            </div>

          </div>

          <UniqMetric

            className = "mobile"

            showGooglePopup = { showGooglePopup }
            showYandexPopup = { showYandexPopup }

            yandexMetricIsUniq    = { yandex_metrics_key !== yandex_metrics_default_key  }
            googleAnalyticsIsUniq = { google_analytics_key !== google_analytics_default_key }
          
          />
        
        </div>

        <PriceList />

        <div className="company-columns__common__employees company-columns__common company-columns__employees flex flex-col" draggable="false">

          <div className="company-columns__common__header company-columns__common__employees__header flex row">

            <p className="montserrat semibold company-head company-head__addpeople">Сотрудники</p>

            <AddButton 
            
              onClick = { async () => {
              
                changeStates({ 
        
                  employee: {
          
                    id:       "",
                    fullname: "",
                    phone:    "",
                    email:    ""
          
                  } 
          
                });

                showEmployeePopup();
              
              }} 
              className = "company__employee__edit-button"
              
            />

          </div>

          <Table
        
            className = "desktop employee-table"
            rows = { employees }
            selectedRowID = { employee.id }
            action = { handleSelectEmplyee }
            head = { EMPLOYEES_TABLE_SETTINGS.head }
            templateColumns = { EMPLOYEES_TABLE_SETTINGS.template }

          />

          <TableEmployeeMobile

            className = "mobile"
            rows = { employees }
            selectedRowID = { employee.id }
            action = { handleSelectEmplyee }
            head = { EMPLOYEES_TABLE_SETTINGS.head }
            templateColumns = { EMPLOYEES_TABLE_SETTINGS.template }

          />

          <UniqMetric

            className = "desktop"

            showGooglePopup = { showGooglePopup }
            showYandexPopup = { showYandexPopup }

            yandexMetricIsUniq    = { yandex_metrics_key !== yandex_metrics_default_key  }
            googleAnalyticsIsUniq = { google_analytics_key !== google_analytics_default_key }
          
          />

        </div>

      </div>

    </Container>

  );

}

export default Company;
import React, { Fragment, useState, useEffect } from 'react';
import PopUp from '../container';

import Button from "../../components/button";
import TextField from "../../components/textfield";

import "./company_info.scss";
import useGlobal from '../../../store';
import cssIf from '../../../scripts/helpers/class.add.if';
import generatePassword from '../../../scripts/helpers/generate.password';
import { CONSTRUCTOR_URL } from '../../../settings/host';
import textToTranslit from '../../../scripts/helpers/text.to.translit';


const CompanyInfoPopUp = () => {

  const [ Popup, showPopup, hidePopup ] = PopUp();

  const [ globalState, globalActions ] = useGlobal();
  const { partners = [] } = globalState;
  const { companies } = globalActions;

  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [url, setUrl] = useState("");
  const [address, setAddress] = useState("");
  const [inn, setInn] = useState("");
  const [ogrnip, setOgrnip] = useState("");
  const [comment, setComment] = useState("");
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");

  const [ isPasswordResetted, setIsPasswordResetted ] = useState( false );    

  const editMode = +globalState.company.id > 0;
  const isEmailOrPasswordChanged = isPasswordResetted || mail !== globalState.company.email;

  useEffect(() => {

    if ( editMode ) {

      setCompany( globalState.company.name );
      setCity( globalState.company.city );
      setUrl( globalState.company.url );
      setAddress( globalState.company.address );
      setInn( globalState.company.inn );
      setOgrnip( globalState.company.ogrnip );
      setComment( globalState.company.comment );

      setFullname( globalState.company.fio );
      setMail( globalState.company.email );
      setPhone( globalState.company.phone );
      setPass(""); // TODO hide pass -> setPassIsHidden -> reset pass

    }

  }, [ globalState.company.id ]);

  useEffect(() => {

    if ( !globalState.company?.url || globalState.company.url === "" ) {

      let ends = "";
      const finded = partners.find( el => el.city === city );

      console.log(`city="${city}", finded: ${ finded }`, partners);

      if ( finded ) {

        ends = finded.partners.length + 1;

      }

      setUrl( `${ textToTranslit( city ) }${ ends }` );

    }

  }, [ city ])

  function sendData () {

    let popupData = {

      active: editMode ? globalState.company.active : 1,
      company_name: company,
      company_city: city,
      company_url: url,
      company_address: address,
      company_inn: inn,
      company_ogrnip: ogrnip,
      comment: comment,
      owner_fio: fullname,
      owner_phone: phone,
      owner_email: mail

    }

    if ( !editMode || isPasswordResetted ) {
      popupData.owner_password = pass
    }

    if ( editMode ) { companies.changeInfo( globalState.company.id, popupData ); }
    else { companies.add( popupData ) }

    hidePopup();

  }
  
  
  const CONTENT = Popup({

    title: `${ cssIf( editMode, "Редактирование", "Добавление" ) } \n франшизы`,
    withoutPadding: true,
    action: sendData,

    content: (

      <div className="flex company_info">

        <div className="divider__left">

          <p className="montserrat popup-text">Данные компании</p>

          <TextField
          
            type="text"
            title="Название компании:"
            value={ company }
            set = { setCompany }
            
          />

          <div className="flex twoinputs flex">

            <TextField
            
              type="number"
              title="ИНН:"
              value={ inn }
              set = { setInn }
              
            />

            <TextField
            
              type="number"
              title="ОГРНИП:"
              value={ ogrnip }
              set = { setOgrnip }
              
            />

          </div>

          <TextField
          
            type="text"
            title="Город:"
            value={ city }
            set = { setCity }
            
          />

          <div className={`textfield textfield-wrapper textfield-siteurl`}>
              
            <p className="textfield-wrapper__title">Адрес страницы на сайте:</p>

            <div className="textfield-siteurl__fields flex items-center">

              <div className="textfield-siteurl__fields__site desktop">https://radivas-remont.ru/</div>
              <div className="textfield-siteurl__fields__site mobile">radivas-remont.ru/</div>

              <input 

                className="textfield-wrapper__field textfield-siteurl__fields__input"
                placeholder=""
                value={url}
                type="text"
                onChange={ ( event ) => { setUrl( event.target.value ) }}

              />
              
            </div>

          </div>

          <TextField
          
            type="text"
            title="Юридический адрес:"
            value={ address }
            set = { setAddress }
            
          />

          <TextField
          
            type="text"
            title={<Fragment>Комментарий: <span>(необязательно)</span></Fragment>}
            value={ comment }
            set = { setComment }
            isArea={ true }
            rows = { 3 }
            
          />

        </div>

        <div className="divider__right">
              
          <p className="montserrat popup-text">Данные владельца компании</p>

          <TextField
          
            type="text"
            title="ФИО владельца:"
            value={ fullname }
            set = { setFullname }
          
          />

          <TextField
        
            type="number"
            title="Телефон:"
            value={ phone }
            set = { setPhone }
          
          />

          <TextField
        
            title="E-mail:"
            value={ mail }
            set = { setMail }
          
          />

          { ( !editMode || isPasswordResetted ) &&

            <div className="flex relative">

              <TextField

                title="Пароль:"
                value={ pass }
                set = { setPass }

              />

              <Button 

                text="Сгенерировать"
                className="popup__button__generate-pass popup__button__generate-pass__employee popup__button__generate-pass__company"
                action = {() => setPass( generatePassword() )}

              />

            </div>

          }

          {( editMode && !isPasswordResetted ) &&

            <React.Fragment>

              <div className={`textfield textfield-wrapper`}>
                <p className="textfield-wrapper__title">Пароль:</p>
              </div>

              <div 
                className="resetpassword__text"
                onClick={() => { 
                  window.confirm("Вы точно хотите сбросить пароль?") && setIsPasswordResetted( true );
                }}
              >

                Мы не храним пароли в открытом виде.<br/>
                Установить новый пароль вы можете <span>сбросив старый</span>.
                
              </div>

            </React.Fragment>

          }

          {(

            ( !editMode && mail.indexOf('@') !== -1 && mail.indexOf('.') !== -1 && pass.length > 3 && fullname.length > 7 && phone.length > 9 ) 
            || 
            ( editMode && isEmailOrPasswordChanged ) 

            ) &&

            <a 
              href={`mailto:${mail}?body=${ editMode ? "Данные" : "Изменились данные"} для входа в <a href="${ CONSTRUCTOR_URL }">${ CONSTRUCTOR_URL }</a>: <br>логин: ${( !editMode || mail !== globalState.company.email ) ? mail : "тот же, что и был"} <br>пароль: ${( !editMode || isPasswordResetted ) ? pass : "тот же, что и был"}&subject=${ !editMode ? "Добро пожаловать" : "Изменились данные для входа" } в конструктор балконов!`}
              rel="norefferer"
              target="_blank"
            >
              <Button

                className="popup__button__generate-pass__send"
                text = {( <Fragment>Отправить <span className="desktop">владельцу</span> данные для входа на e-mail</Fragment> )}
                // color = "orange"

              />

            </a>

          }

        </div>

      </div>

    )

  });

	return [

    CONTENT,
    showPopup,
    hidePopup

  ]

}

export default CompanyInfoPopUp;
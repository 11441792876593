import React from 'react';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import "./review.scss";


const Review = ({ author = "", text = "", images = [], video = "", videoPreview = "", isPublished = false, remove }) => {

  return (

    <div className="review flex justify-between relative">

      { isPublished
      
        ? <div className="review__buttons flex absolute">

            <div className="review__buttons__grant relative pointer" onClick = { remove } >

              <p className="montserrat">Опубликовать</p>
              
            </div>

            <div className="review__buttons__denay relative pointer">

              <p className="montserrat">Отклонить</p>

            </div>

          </div>

        : <div className="review__buttons__delete absolute pointer" onClick = { remove } >

            <p className="montserrat">Удалить отзыв</p>

          </div>

      }

      <div className="review__client flex flex-col">

        <p className="review__client__name montserrat">{ author }</p>
        <p className="review__client__text montserrat">{ text }</p>

      </div>

      <div className="review__images flex flex-row-reverse">

        { images.length > 0 && images.map(( el, key ) => (
        
          <div key = { key } className="review__images__image relative">

            <img src={ el } />

          </div>
        
        ))}

        {( videoPreview && videoPreview !== "" ) &&

        <div 
          className = "review__images__image review__images__video flex justify-center items-center" onClick = { () => { alert("Посмотреть видео-отзыв можно на сайте") }}
          title = "Посмотреть видео-отзыв можно на сайте"
        >

          <PlayIcon />

          <div className="review__images__video__darker" />
          
          <img 
            
            src = { videoPreview } 
            className = "review__images__img review__images__video__preview"
            
          />

        </div>

        }

      </div>

    </div>

  );

}

export default Review;
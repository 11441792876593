import React from 'react';

import './header.scss';
import Tabs from './tabs';

const Header = () => {

  return (
  
    <header className="flex items-center">

      <div className="header__panel">

        <h1 className="admin-panel__div_name">Панель администрирования</h1>

      </div>
      
      <div id="header__tabs">
        
        { window.location.pathname !== "/sign" && <Tabs /> }
      
      </div>
            
      <div className="header__user"></div>

    </header>
  
  );
  
}
  
export default Header;
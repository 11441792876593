import React, { Fragment, useEffect, useState } from 'react';
import PopUp from '../container';
import Button from "../../components/button";
import TextField from "../../components/textfield";
import useGlobal from '../../../store';
import "./company_google.scss";


const CompanyGooglePopUp = () => {

  const [ Popup, showPopup, hidePopup ] = PopUp();

  const [ globalState, globalActions ] = useGlobal();
  const { company, dummy } = globalState;
  const { companies } = globalActions;

  const {

    google_analytics_key,
    google_analytics_default_key

  } = company;

  const [ code, setCode ] = useState( google_analytics_key || "" );

  const tip = ( code === google_analytics_key || code === google_analytics_default_key ) 

    ? google_analytics_key === google_analytics_default_key 

      ? ` (сейчас используется общий)` 
      : ` (сейчас используется личный)`

    : ``;
  
  useEffect(() => { setCode( google_analytics_key ); }, [ google_analytics_key ]);

  function clearFields() { setCode(""); }

  function closePopup() {

    hidePopup();
    clearFields();

  }

  function sendData({ setToDefault = false }) {

    let popupData = {

      metric_name: `google_analytics_key`,
      metric_value: !setToDefault ? code : ""

    }

    companies.editMetric( company.id, popupData, closePopup );

  }

  const setToDefault = () => {

    const ask = `Действительно удалить личный рекламный кабинет, и назначить общий?`;
    window.confirm( ask ) && sendData({ setToDefault: true });
    
  }
  
  const CONTENT = Popup({

    title: 'Google Analytics',
    withoutPadding: true,
    action: sendData,

    content: (

      <div className="flex">

        <div className="popup__content popup-google">

          <span style = {{ display: 'none' }}>{ dummy }</span>

          <TextField
            
            title = {`Код счётчика${ tip }:`}
            value = { code }
            set   = { setCode }
            placeholder = "G-XXXXXXXXX"
            
          />

          <p className="popup-google__subtitle">Подсказка: в поле нужно вставить <br className="mobile" />только номер (он подчёркнут)</p>
          
          <img src="/images/popup-google/sample-desktop.jpg" className="popup-google__image desktop" />
          <img src="/images/popup-google/sample-mobile.jpg" className="popup-google__image mobile" />

          <Button

            text = "Сохранить"
            action = { sendData }

          />

          <Button

            color  = "red"
            action = { setToDefault }
            text   = {(

              <Fragment>
                
                <span className = "desktop">Удалить общий счётчик, и назначить личный</span>
                <span className = "mobile">удалить и назначить общий</span>
                
              </Fragment>
                
            )}
          
          />

        </div>

      </div>

    )

  });

	return [

    CONTENT,
    showPopup,
    hidePopup

  ]

}

export default CompanyGooglePopUp;
import React, { useEffect, Fragment } from 'react';
import cookie from 'react-cookies';
import useGlobal from '../../store';

//подключение роутера
import { Routes, Route, BrowserRouter }	from 'react-router-dom';

/* импорт страниц */

//страницы авторизации
import Sign from './sign';

//страницы контента
import Materials from './materials';
import Company from './company';
import Companies from './companies';
import Site from './site';
import MenuMobile from '../components/menu-mobile';

const AppRouter = () => {

	return (

		<Fragment>

		<BrowserRouter>

			<Routes>

        <Route exact path="/"              element={<Protected><Companies/></Protected>} />
        <Route exact path="/sign"          element={<Sign/>} />
        <Route exact path="/materials"     element={<Protected><Materials/></Protected>} />
        <Route exact path="/companies"     element={<Protected><Companies/></Protected>} />
        <Route exact path="/companies/:id" element={<Protected><Company /></Protected>} />
        <Route exact path="/site"					 element={<Protected><Site /></Protected>} />

      </Routes>

    </BrowserRouter>

		</Fragment>

  );

};

const Protected = ({ children }) => {

	const [ globalState, globalActions ] = useGlobal();
	const token = cookie.load('token');

	useEffect(() => { 
    //// console.log(`Protected token = `, token);		
		token 
			? globalActions.sign.Check( token )
			: globalActions.sign.Out()
	
	}, []);

	return children;

}

export default AppRouter;
import React from 'react';
import './textfield.scss';

const TextField = ({ value = "", set = () => {}, title = "", type = "text", placeholder = "", className = "", isArea = false, rows = 5, maxLength = 500000 }) => (
        
  <div className={`textfield textfield-wrapper ${ className }`}>

    { title !== ""
    
      ? <p className="textfield-wrapper__title">{ title }</p>
      : ""
      
    }

    { !isArea ?

        <input 
        
          className="textfield-wrapper__field"
          placeholder={ placeholder }
          value={ value }
          type={ type }
          onChange={ ( event ) => { set( event.target.value ) }}
          maxLength = { maxLength }

        />
        
      : <textarea 
        
          className="textfield-wrapper__field"
          placeholder={ placeholder }
          value={ value }
          type={ type }
          rows={ rows }
          onChange={ ( event ) => { set( event.target.value ) }}
          maxLength = { maxLength }

        />

    }

    </div>

);

export default TextField;
import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import Card from '../../components/table.card';

import './companies.scss';

import { ReactComponent as ButtonChecker } from './button-checker.svg';
import { ReactComponent as Search } from './search.svg';


import Button from '../../components/button';
import TextField from '../../components/textfield';

import Table from '../../components/table';

import CompanyInfoPopUp from '../../popups/company.info';
import useGlobal from '../../../store';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import MenuMobile from '../../components/menu-mobile';

const Companies = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { companiesList } = globalState;
  const { companies } = globalActions;

  const [ search, setSearch ] = useState("");

  const navigate = useNavigate();
  const [ Dummy, reloadDummy ] = useReloadDummy();

  const TABLE_SETTINGS = {

    head: [

      "Город",
      "Название компании",
      "Владелец",
      "Телефон",
      "Сотрудников",
      "Замеров",
      "Договоров",
      "Добавлена"

    ],

    template: "11% 1fr 24% 9% 9% 6% 8% 8%"

  }

  const [ CompanyPopup, showCompanyPopup, hideCompanyPopup ] = CompanyInfoPopUp();

  useEffect(() => { companies.getCompaniesInCities() }, [] )
  useEffect(() => { companies.all( search, reloadDummy ); console.log(`uEF companies.all search="${ search }"`) }, [ search ])

  return (

    <Fragment>

      { CompanyPopup }

      <Container className = "container__companies companies relative">

        <MenuMobile pages = { 1 } />

        <div className="companies__header flex">

          <div className="companies__header__headmobile flex">

            <p className="companies__head montserrat">Франшизы</p>
          
            <div className="companies__add-button__container relative">

              <Button
              
                text="Добавить"
                icon = { <ButtonChecker /> }
                className="companies__add-button__button"
                action={ showCompanyPopup }

              />

            </div>

          </div>

          <div className="companies__search__container flex center items-center">

            <Search className="companies__search__icon" />

            <TextField
            
              value = { search }
              set = { setSearch }
              placeholder="Поиск"
              className = "companies__search__textfield"

            />

          </div>

        </div>

        <Dummy />

        <Table
        
          className = "desktop"
          head = { TABLE_SETTINGS.head }
          rows = { companiesList }
          action = { id => navigate(`/companies/${ id }`) }
          templateColumns = { TABLE_SETTINGS.template }

        />

        <div className="companies__cardlist mobile">

          { companiesList.map(( el, key ) => (

              <Card 
              
                key = { key }
                city = { el.city }
                date = { el.reg_date }
                measures = { el.projects }
                orders = { el.contracts }
                people = { el.employees }
                company = { el.company_name }
                allnames = { el.owner_name }
                phone = { el.phone }
                action = { () => navigate(`/companies/${ el.id }`) }
              
              />

           ))}

        </div>

      </Container>

    </Fragment>

  );

}

export default Companies;
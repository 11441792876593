import React from 'react';
import useGlobal from '../../../store';
import CheckItem from '../checkitem';
import cssIf from '../../../scripts/helpers/class.add.if';

import "./pricelist.scss";

const PriceList = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { pricelist } = globalState;
  const { companies } = globalActions;
  //// console.log( pricelist );
  return (

    <div className="company-columns__common pricelist relative" draggable="false">

      <div className="company-columns__common__header__price company-columns__common__header flex row">

        <p className="montserrat semibold company-head desktop">Прайс-лист</p>
        <p className="montserrat semibold company-head company-head__typeprice company-head__typeprice__a desktop">Общая цена</p>
        <p className="montserrat semibold company-head company-head__typeprice company-head__typeprice__b desktop">За работу</p>

        {/* <div /> */}

      </div>

      <div className="pricelist__content flex">

        { pricelist?.length > 0 && pricelist.map(( el, key ) => ( el.id === "sills" ? null :

          <PriceListGroup

            key = { key }
            type = { el.type }
            title = { el.name }
            content = { el.content }
            action = { companies.changePriceList }

          />

        ))}

      </div>

    </div>

  );

}

const PriceListGroup = ({ title, type, content, action }) => {

  const contentWithOnePrice = content.filter( el => el?.price?.toString().indexOf(`{`) === -1 || !el?.price );
  const contentWithMultiPrice = content.filter( el => el?.price?.toString().indexOf(`{`) !== -1 );

  // const contentWithOnePrice = content.filter( el => el?.price?.toString().indexOf(`{`) === -1 || !el?.price );
  // const contentWithMultiPrice = content.filter( el => el?.price?.toString().indexOf(`{`) !== -1 || el?.pricePerJob?.toString().indexOf(`{`) !== -1 );
  
  return (

    <div className="pricelist__content_box">

      <div className="pricelist__content_head">

        <p className="montserrat semibold">{ title }:</p>

      </div>

      <div className = "pricelist__content_list">

        { contentWithOnePrice?.length > 0 && contentWithOnePrice.map(( el, key ) => (

          <CheckItem

            key     = { key }
            id      = { el.id }
            price   = { el.price }
            unit    = { el.unit?.replace("п.м.","пм") }
            text    = { el.name }
            action  = {( newPrice, newPricePerJob ) => {

              if ( +newPrice === +el.price && +newPricePerJob === +el.pricePerJob ) { return; }

              action({

                pricelistType: el?.pricelistType || type,
                targetID: el.id,
                price: newPrice,
                pricePerJob: newPricePerJob

              });
                
            }}

            className = { cssIf( el?.isDefault, "checkitem--default" ) }
            pricePerJob = { el.pricePerJob }

          />

        ))}

        { contentWithMultiPrice?.length > 0 && contentWithMultiPrice.map(( el, key ) => (

          <MultiPriceElement

            key    = { key }
            id     = { el.id }
            type   = { type }
            name   = { el.name }
            unit   = { el.unit?.replace("п.м.","пм") }
            prices = { el.price }
            action = { action }
            pricesPerJob = { el.pricePerJob }

          />

        )) }

      </div>

    </div>

  )

}

const MultiPriceElement = ({ id, type, name, unit, prices, pricesPerJob, action }) => {

  const pricesJSON = JSON.parse( prices );

  const namesArr = [];
  const pricesArr = [];
  
  let pricesPerJobJSON = {};
  const pricesPerJobArr = [];

  if ( pricesPerJob && pricesPerJob.toString().indexOf('{') !== -1 ) {

    pricesPerJobJSON = JSON.parse( pricesPerJob );

    for ( let name in pricesPerJobJSON ) {

      pricesPerJobArr.push( pricesPerJobJSON[ name ] );

    }

  }

  for ( let name in pricesJSON ) {

    namesArr.push( name );
    pricesArr.push( pricesJSON[ name ] );

  }

  //// console.log(`MultiPriceElement [${ id }] ${ name } prices`, prices);
  //// console.log(`MultiPriceElement [${ id }] ${ name } prices JSON.parsed`, pricesJSON);
  //// console.log(`MultiPriceElement [${ id }] ${ name } namesArr`, namesArr);
  //// console.log(`MultiPriceElement [${ id }] ${ name } pricesArr`, pricesArr);

  const shortNames = {

    "Столешница деревянная": "Размер",
    "Демонтаж порога": { bricks: "Кирпичный дом", panel: "Панельный дом" },
    "Остекление": { standart: "Стандартное", premium: "Премиальное", lamination: "С ламинацией" },
    "Подоконник бюджетный": `Ширина $X$мм`,
    "Подоконник премиальный": `Ширина $X$мм`,
    "Сушилка для белья": `Размер $X$м`,
    "Откос: 1-2х створчатое комнатное окно": { bricks: "Кирпич", panel: "Панель" },
    "Откос: 3х створчатое комнатное окно": { bricks: "Кирпич", panel: "Панель" },
    "Откос: балконный выход с окном": { bricks: "Кирпич", panel: "Панель" },
    "Откос: балконный выход чебурашка": { bricks: "Кирпич", panel: "Панель" },
    "Перила из хромированных труб": { m3: "3м балкон", m6: "6м балкон" },
    "Расходники по обшивке": { m3: "3м балкон", m6: "6м балкон" },
    "Шкаф": {
      tumbMax13: "Ларь или тумба < 1.3м.",
      tumbMax20: "Тумба 1.3м - 2м.",
      cupbMax17: "Шкаф < 1.7м",
      cupbMax20: "Шкаф 1.7м - 2.0м",
      tumbAngleCupb: "Угл. шкаф + тумба",
    }

  }
  
  function priceRowName( elName, sizeName ) {
    
    let result = elName;

    if ( shortNames?.[ elName ] ) {

      result = ( typeof shortNames[ elName ] === 'object' && shortNames[ elName ]?.[ sizeName ] )
        
        ? shortNames[ elName ][ sizeName ]
        : shortNames[ elName ].indexOf(`$X$`) !== -1

          ? `${ shortNames[ elName ].replace( "$X$", sizeName ) }`
          : `${ shortNames[ elName ] } ${ sizeName }`

    } else {

      result +=` ${ sizeName }`

    }

    return result;
  
  }

  return (

    <div className ="pricelist__content_box">

      <div className = "pricelist__content_head">

        <p className="montserrat semibold">{ name }:</p>

      </div>

      <div className = "pricelist__content_list">

        { namesArr?.length > 0 && namesArr.map(( size, key ) => (

          <CheckItem

            key     = { key }
            id      = { id }
            price   = { pricesJSON[ size ] }
            unit    = { unit?.replace("п.м.","пм") }
            text    = { `${ priceRowName( name, size ) }` }
            action  = {( newPrice, newPricePerJob ) => {

              if ( 
                +newPrice === +pricesJSON[ size ] && 
                ( 
                  +newPricePerJob === +pricesPerJobJSON[ size ] 
                  ||
                  +newPricePerJob === pricesPerJobArr[ key ]
                )
              ) { return; }

              action({

                pricelistType: type,
                targetID: id,
                price: newPrice,
                pricePerJob: newPricePerJob,
                isMultiPrice: true,
                multiPriceIndex: size

              });
                
            }}
            pricePerJob = { pricesPerJobJSON?.[ size ] || 0 }
            //TODO className = { cssIf( el?.isDefault, "checkitem--default" ) }
          />

        ))}

      </div>

    </div>

  );

}

export default PriceList;
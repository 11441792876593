import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as MaterialsIcon } from './icons/materials.svg';

import './header.scss';


const Tabs = () => {

  const MENU = [

    {

      path: '/companies',
      text: 'Франшизы',
      icon: <img src = "/images/icons/user.jpg" style = {{ maxHeight: "1.15vw", objectFit: "contain" }} />

    },
    
    // {

    //   path: '/materials',
    //   text: 'Материалы',
    //   icon: <MaterialsIcon />

    // },

    {

      path: '/site',
      text: 'Сайт',
      icon: <img src="/images/icons/site.jpg" />

    }    

  ]

  return (
  
    <div className="header__tabs flex items-center">

      { MENU.map( ( el, key ) => (

        <Tab 
        
          key = { key }
          icon = { el.icon }
          text = { el.text }
          path = { el.path }
          isActive = { window.location.pathname.indexOf( el.path ) !== -1 }
        
        />
      
      ))}

    </div>
  
  );
  
}

const Tab = ({ icon, text, path, isActive }) => {

  return (
  
    <Link 
    
      to = { path }
      className = {`header__tabs__tab flex items-center ${ isActive ? 'header__tabs__tab--active' : '' }`}
      
    >

      <div className="header__tabs__tab__icon">

        { icon }

      </div>      
      
      <div className="header__tabs__tab__text">

        { text }

      </div>
      

    </Link>
  
  );
  
}
  
export default Tabs;
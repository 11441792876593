import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';

import './table.scss';

const Table = ({ head = [], rows = [], className="", templateColumns = "", action = false, selectedRowID = false }) => {

  return (

    <section className = {`table ${ className }`}>

      <Row

        head
        content = { head }
        templateColumns = { templateColumns }

      />

      <div className = "table__rows">

        { rows.length > 0 && rows.map(( row, key ) => {

          const tempContent = [];

          for ( let colName in row ) {

            tempContent.push( row[ colName ] )

          }

          return (

            <Row

              key = { key }
              action = { () => action( row?.id )}
              content = { tempContent }
              templateColumns = { templateColumns }
              isSelected = { selectedRowID === row?.id }

            />

          )

        })}

      </div>

    </section>

  );

}

export default Table;

const Row = ({ content = [], templateColumns = "", action = false, head = false, isSelected }) => (

  <div 
    className = {`table__row grid items-center ${ cssIf( head, 'table__row--head' ) } ${ cssIf( isSelected, 'table__row--selected' ) }`} 
    style = {{ gridTemplateColumns: templateColumns }}
    onClick = { () => action && action() }
  >

    { content.length > 0 && content.map(( col, key ) => ( 

      ( key + 1 ) <= templateColumns.split(" ").length &&

        <div className = "table__row__col" key = { key } >{ col }</div>

    ))}

  </div>

);
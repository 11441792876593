import React from 'react';

import './popup.scss';

import { ReactComponent as Done } from './img/done.svg';
import { ReactComponent as Close } from './img/close.svg';

const PopUp = () => {

	{/* генерация id */}
	const id = Math.random(100000,999999);

	{/* функция вызова popup */}
	const show = () => document.getElementById(id).style.display = window.innerWidth > 800 ? "flex" : "block";
	{/* функция закрытия popup */}
	const hide = () => document.getElementById(id).style.display = "none";

	{/* основной контент */}
	const CONTENT = ({ title = "Добавление объекта", action = () => {}, content = "", withoutPadding = false }) => (
		
    <div id={id} className = "popup">

      <div className = "popup__body">

        <div className= "popup__body__header">

					<div className="closing-img">

          	<Close className="pointer" onClick={hide} />

					</div>

          <p className="montserrat semibold">{title}</p>

          <Done className="pointer" onClick={action} />

        </div>

        <div className="popup__body__content" style = { withoutPadding && { padding: 'unset' } }>

          {content}

        </div>

      </div>

    </div>

	)

	return [

		CONTENT,
		show,
		hide

	];

}

export default PopUp;
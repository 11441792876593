import React, { useState } from 'react';

const useReloadDummy = () => {

  const [ dummy, setDummy ] = useState( RandomHash() )

  const Reload = () => { 

    const r = RandomHash();
    setDummy( r );

  };

  const Anchor = () => (

    <span style = {{ display: 'none' }}>
      { dummy }
    </span>

  )

  return [ Anchor, Reload ];

}

export const RandomHash = () => Date.now().toString();

export default useReloadDummy;
import React, { Fragment, useState, useEffect } from 'react';
import PopUp from '../container';
import Button from "../../components/button";
import TextField from "../../components/textfield";
import generatePassword from '../../../scripts/helpers/generate.password';
import useGlobal from '../../../store';
import "./company_employee.scss";
import cssIf from '../../../scripts/helpers/class.add.if';
import { CONSTRUCTOR_URL } from '../../../settings/host';


const CompanyEmployeePopUp = () => {

  const [ Popup, showPopup, hidePopup ] = PopUp();

  const [ globalState, globalActions ] = useGlobal();
  const { employee, dummy } = globalState;
  const { companies, changeStates } = globalActions;

  const [ fullname, setFullname ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ pass, setPass ] = useState("");

  const [ isPasswordResetted, setIsPasswordResetted ] = useState( false );

  const editMode = +employee?.id > 0;
  const isEmailOrPasswordChanged = isPasswordResetted || email !== employee.email;

  useEffect( () => {

    if ( editMode ) {

      setFullname( employee.fullname )
      setPhone( employee.phone )
      setEmail( employee.email )

    } else {

      clearFields();

    }

  }, [ employee.id ]);

  function clearFields() {

    setFullname("");
    setPhone("");
    setEmail("");
    setPass("");

  }

  function closePopup() {

    hidePopup();
    clearFields();

  }

  function sendData () {

    let popupData = {

      fullname: fullname,
      phone: phone,
      email: email      

    }

    if ( !editMode || isPasswordResetted ) {

      popupData.pass = pass;

    }

    if ( editMode ) { companies.editEmployee( popupData, closePopup ) }
    else { companies.addEmployee( popupData, closePopup ) }

    console.log('sendData', popupData );

  }
  
  const CONTENT = Popup({

    title: `${ cssIf( editMode, "Редактирование", "Добавление" )}  \n сотрудника`,
    withoutPadding: true,
    action: sendData,

    content: (

      <div className="flex">

        <div className="popup__content popup__content__employee">

          <span style = {{ display: 'none' }}>{ dummy }</span>

          <TextField
          
            type="text"
            title={ <Fragment><p className="desktop">Фамилия Имя Отчество:</p><p className="mobile">ФИО</p></Fragment> }
            value={ fullname }
            set = { setFullname }
            
          />

          <TextField
          
            type="number"
            title="Телефон:"
            value={ phone }
            set = { setPhone }
            
          />

          <TextField
          
            type="email"
            title="E-mail:"
            value={ email }
            set = { setEmail }
            
          />

          { ( !editMode || isPasswordResetted ) &&

            <div className="flex relative">

              <TextField
          
                title="Пароль:"
                value={ pass }
                set = { setPass }
            
              />

              <Button 

                text="Сгенерировать"
                className="popup__button__generate-pass popup__button__generate-pass__employee popup__button__generate-pass__company"
                action = {() => setPass( generatePassword() )}

              />

            </div>

          }

          {( editMode && !isPasswordResetted ) &&

            <React.Fragment>

              <div className={`textfield textfield-wrapper`}>
                <p className="textfield-wrapper__title">Пароль:</p>
              </div>

              <div 
                className="resetpassword__text"
                onClick={() => { 
                  window.confirm("Вы точно хотите сбросить пароль?") && setIsPasswordResetted( true );
                }}
              >

                Мы не храним пароли в открытом виде.<br/>
                Установить новый пароль вы можете <span>сбросив старый</span>.
                
              </div>

            </React.Fragment>
            
          }

          {(

            ( !editMode && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && pass.length > 3 && fullname.length > 7 && phone.length > 9 ) 
            || 
            ( editMode && isEmailOrPasswordChanged ) 
            
          ) &&
            
            <a 
              href={`mailto:${email}?body=${ editMode ? "Данные" : "Изменились данные"} для входа в <a href="${ CONSTRUCTOR_URL }">${ CONSTRUCTOR_URL }</a>: <br>логин: ${( !editMode || email !== employee.email ) ? email : "тот же, что и был"} <br>пароль: ${( !editMode || isPasswordResetted ) ? pass : "тот же, что и был"}&subject=${ !editMode ? "Добро пожаловать" : "Изменились данные для входа" } в конструктор балконов!`}
              rel="norefferer"
              target="_blank"
            >

              <Button

                className="popup__button__generate-pass__send"
                text = {( <Fragment>Отправить <span className="desktop">сотруднику</span> данные для входа на e-mail</Fragment>)}
                // color = "orange"

              />

            </a>
          
          }

        </div>

      </div>

    )

  });

	return [

    CONTENT,
    showPopup,
    hidePopup

  ]

}

export default CompanyEmployeePopUp;
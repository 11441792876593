import React, { useState, useEffect } from 'react';
import PopUp from '../container';
import TextField from "../../components/textfield";
import useGlobal from '../../../store';

const SiteMaintextPopUp = () => {

  const [ Popup, showPopup, hidePopup ] = PopUp();

  const [ globalState, globalActions ] = useGlobal();
  const { main_text } = globalState;
  const { site } = globalActions;
  const textMaxLength = 88;
  
  const [ text, setText ] = useState( main_text );
  
  useEffect(() => { text !== main_text && setText( main_text ) }, [ main_text ]);

  function clearFields() { setText(""); }

  async function sendData() {

    try {

      if ( text.length > textMaxLength ) { alert(`Максимально допустимое кол-во символов включая пробелы: ${ textMaxLength }. Удалите лишние символы.`); return; }

      const popupData = { text }

      site.chageMainText( popupData );

      clearFields();
      hidePopup();

    } catch ( err ) {

      alert( err );

    }

  }

  const CONTENT = Popup({

    title: "Текст на главном экране",
    withoutPadding: true,
    action: sendData,

    content: (

      <div className="flex">

        <div className="popup__content popup__content__maintext">

          <TextField
          
            isArea
            type = "text"
            title = "Если оставить поле пустым, будет выводиться стандартный текст"
            value = { text }
            set = { setText }
            maxLength = { textMaxLength }

          />

        </div>

      </div>

    )

  });

	return [

    CONTENT,
    showPopup,
    hidePopup

  ]

}

export default SiteMaintextPopUp;
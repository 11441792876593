import { HOST } from "./host";

const COMPANIES = `${ HOST }/companies`;
const SITE = `${ HOST }/landing`;

const API = {

  sign: {

    in: `${ HOST }/sign/in`,
    check: `${ HOST }/sign/check`

  },

  companies: {

    all: `${ COMPANIES }/all`,
    get: `${ COMPANIES }/get`,
    getStats: `${ COMPANIES }/get/stats`,
    add: `${ COMPANIES }/add`,
    edit: `${ COMPANIES }/edit`,
    editMetric: `${ COMPANIES }/edit/metric`,
    remove: `${ COMPANIES }/remove`,
    pricelist: {

      add: `${ COMPANIES }/pricelist/add`,
      edit: `${ COMPANIES }/pricelist/edit`

    },
    employees: {

      add: `${ COMPANIES }/employees/add`,
      edit: `${ COMPANIES }/employees/edit`,
      remove: `${ COMPANIES }/employees/remove`

    }

  },

  site: {

    getAllData: `${ SITE }/getAllData`,
    chageMainText: `${ SITE }/change-maintext`,

    reviews: {

      // all: `${ SITE }/reviews`,
      add: `${ SITE }/reviews/add`,
      confirm: `${ SITE }/reviews/confirm`,
      remove: `${ SITE }/reviews/remove`

    },

    portfolio: {

      // all: `${ SITE }/portfolio`,
      add: `${ SITE }/portfolio/add`,
      remove: `${ SITE }/portfolio/remove`

    },

  }

}

export { API };
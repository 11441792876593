import GlobalHook from "use-global-hook";
import * as actions from "./actions";
import twoDigitFormat from "../scripts/helpers/two.digit.format";
import { STATS_PERIOD } from "../settings/constants";

const today = new Date();

const initialState = {

  dummy: 0,

  user_name: "",
  user_role: "",

  companiesList: [],

  company: {

    id: "",
    active: 1,
    name: "",
    city: "",
    url: "",
    address: "",
    inn: "",
    ogrnip: "",
    reg_date: "",
    comment: "",
    fio: "",
    email: "",
    phone: "",
    employees: 0,
    projects: 0,
    contracts: 0,
    allProfit: 0,
    last_date: "",
    google_analytics_key: false,
    yandex_metrics_key: false,
    google_analytics_default_key: false,
    yandex_metrics_default_key: false,

  },
  pricelist: [],
  employees: [],

  employee: {

    id: "",
    fullname: "",
    phone: "",
    email: "",
    pass: ""

  },

  partners: [],

  reviews: [],
  portfolio: [],

  main_text: "",

  statsPeriod: STATS_PERIOD.ALL,
  startDate: `${ twoDigitFormat( today.getDate() ) }.${ twoDigitFormat( today.getMonth() + 1 ) }.${ today.getFullYear() }`,
  endDate: `${ twoDigitFormat( today.getDate() ) }.${ twoDigitFormat( today.getMonth() + 1 ) }.${ today.getFullYear() }`,

}

const useGlobal = GlobalHook( initialState, actions );

export default useGlobal;
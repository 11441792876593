import React from 'react';

import TextField from '../textfield';

import Button from '../../components/button';

import './textfieldURL.scss';


const TextFieldURL = ({ value = "", setValue = "", title = "", type = "", placeholder = "", className= "", action = "", buttonText = "" }) => {

  return (


    <div className={`textfieldURL-wrapper relative ${ className }`}>
      
      <TextField

        value = {value}
        setValue = {setValue}
        title = {title}
        type = {type}
        placeholder = {placeholder}

      />

      <Button 
      
        text = "Выбрать файл"
        color = "yellow"
        className = "textfield-button"
      
      />

    </div>

  );

}

export default TextFieldURL;
import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './sign.scss';

import TextField from '../../components/textfield';
import Container from '../../components/container';

import Button from '../../components/button';
import useGlobal from '../../../store';

const Sign = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;

  const navigate = useNavigate();

  const [ mail,  setMail  ] = useState("");
  const [ pass,  setPass  ] = useState("");
  const [ error, setError ] = useState("");

  useEffect(() => { error !== "" && setError(""); }, [ mail, pass ]);
  
  return (

    <Fragment>

      <Container className="container__sign">

        <div className="sign-auth__block">

          <div className="sign-auth__block__title flex center">

            <p className="montserrat">Авторизация</p>

          </div>

          <div className="sign-auth__block__fields">

            <TextField

              type="email"
              title="E-mail"
              value={ mail }
              set = { setMail }

            />

            <TextField
            
              type="password"
              title="Пароль"
              value={ pass }
              set = { setPass }

            />

            { error !== "" ? <p className="error">{ error }</p> : '' }

          </div>

          <div className="sign-auth__block__button">

            <Button
            
              text="Войти"
              className="sign-auth__block__button__item"
              action = { () => {
                sign.In( 
                  mail, 
                  pass,
                  () => navigate('/companies'),
                  setError
                ) 
              }}
            
            />

          </div>

        </div>

      </Container>

    </Fragment>

    );

  }
  
  export default Sign;
import React, { useState } from 'react';

import Container from '../../components/container';
import TextField from '../../components/textfield';
import TextFieldURL from '../../components/textfieldURL';
import ToggleChecker from '../../components/togglechecker';

import Button from '../../components/button';

import { ReactComponent as AddButton } from './img/addButton.svg';

import ListItem from '../../components/listitem';

import './materials.scss';

import CompanyMaterialPopUp from '../../popups/company.material';
import MenuMobile from '../../components/menu-mobile';



const Materials = () => {

  const [text, setText] = useState("");
  const [ useTexturePreview, setUseTexturePreview] = useState( false );
  const [ useCompany_0, setUseCompany_0] = useState( false );
  const [ useCompany_1, setUseCompany_1] = useState( false );

  const [ MaterialPopup, showMaterialPopup, hideMaterialPopup ] = CompanyMaterialPopUp();

  return (

    <Container className="container__materials">

      <MenuMobile page = { 3 } />

      { MaterialPopup }

      <div className="flex row">
        
        <p className="montserrat font-semibold">Материалы</p>
      
      </div>

      {/*контейнер спецификаций*/}
      <div className="flex row columns-container">

        {/*колонки спецификаций*/}

        <div className="flex col materials__columns flex  materials__columns-types"> 
        
          <div class="flex justify-between materials__header">

            <p className="montserrat materials-head font-semibold">Типы материалов:</p>
            <AddButton onClick={showMaterialPopup} />

          </div>

          <div className="materials__columns_content flex col">

            <p className="montserrat materials-head materials-head__subhead font-semibold">Стены и потолок:</p>

              <ListItem

                imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
                text = "Паркет"
                isActive = {true}

              />

              <ListItem

                imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
                text = "Ламинат"
                isActive = {false}

              />

              <ListItem

                imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
                text = "Линолеум"
                isActive = {false}

              />

              <ListItem

                imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
                text = "Вагонка"
                isActive = {false}

              />

            <p className="montserrat materials-head materials-head__subhead font-semibold">Пол:</p>

            <ListItem

              imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
              text = "Паркет"
              isActive = {true}

            />

            <ListItem

             imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
              text = "Ламинат"
              isActive = {false}

            />

            <ListItem

              imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
              text = "Линолеум"
              isActive = {false}

            />

            <ListItem

              imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
              text = "Вагонка"
              isActive = {false}

            />

          </div>
        
        </div>


        <div className="flex col materials__columns materials__columns-subtypes">

          <div class="flex justify-between materials__header">

            <p className="montserrat materials-head font-semibold">Материалы группы:</p>
            <AddButton />

          </div>

          <div className="materials__columns_content flex col">

            <ListItem

              imgUrl = "https://thumbs.dreamstime.com/b/laminate-background-wooden-laminate-parquet-boards-floor-interior-design-texture-pattern-natural-wood-169748496.jpg"
              text = "Красное дерево"
              isActive = {true}

            />

          </div>

        </div>

        <div className="flex col materials__columns materials__columns-parameters"> 
        
          <div class="flex justify-between materials__header">

            <p className="montserrat materials-head font-semibold">Материал группы</p>
            
            <Button />

          </div>

            <div className="materials__columns_content flex col">

              <TextField 
          
                value = {text}
                setValue = {setText}
                title = "Название:"
                placeholder = "Красное дерево"
            
              />

              <TextFieldURL 
          
                value = {text}
                setValue = {setText}
                title = "Текстура:"
                placeholder = "URL"
          
              />

              <div className="flex row fields-container">

                <div className="fields-container__content">

                  <TextField 
            
                    value = {text}
                    setValue = {setText}
                    title = "Размер:"
                    placeholder = "2, 2, 2"
              
                  />

                </div>

                <div className="fields-container__content">

                  <TextField 
                
                    value = {text}
                    setValue = {setText}
                    title = "Блеск:"
                    placeholder = "1"
                
                  />

                </div>

              </div>

              <ToggleChecker

                value = { useTexturePreview }
                set = { setUseTexturePreview }
                title = "Превью:"
                text = "Использовать файл текстуры для превью:"
                anchorPosition = "right"
                
              />

              <TextFieldURL 
          
                value = {text}
                setValue = {setText}
                placeholder = "URL"
    
              />

              <Button 
              
                text = "Удалить материал"
                color = "red"
                className = "button-del"

              
              />

            </div>

        </div>

        <div className="flex col materials__columns materials__columns-available"> 
        
          <div class="flex justify-between materials__header">

            <p className="montserrat materials-head font-semibold">Материал доступен франшизам:</p>

          </div>
          
          <div className="materials__columns_content flex col">

            <ToggleChecker
              value = {useCompany_0}
              set = {setUseCompany_0}
              text = "РадиВас"
            />

            <ToggleChecker
              value = {useCompany_1}
              set = {setUseCompany_1}
              text = "ВсемБалконы"
            />

          </div>

        </div>

      </div>

    </Container>

  );

}

export default Materials;
import React, { Fragment, useState, useEffect } from 'react';
import PopUp from '../container';

import { ReactComponent as ImageEmpty } from './images.empty.svg';
import { ReactComponent as VideoEmpty } from './video.empty.svg';

import TextField from "../../components/textfield";

import "./site_review.scss";
import ImageLoader from '../../components/image.loader';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import useGlobal from '../../../store';
import VideoLoader from '../../components/video.loader';

const SiteReviewPopUp = () => {

  const [ Popup, showPopup, hidePopup ] = PopUp();
  const [ Dummy, reloadDummy ] = useReloadDummy();

  const [ globalState, globalActions ] = useGlobal();
  const { site } = globalActions;

  const [ name, setName ] = useState("");
  const [ review, setReview ] = useState("");
  const [ images, setImages ] = useState([]);
  const [ video, setVideo ] = useState("");
  const [ videoPreview, setVideoPreview ] = useState("");
  
  function clearFields() {

    setName("");
    setReview("");
    setImages([]);

  }

  function addImage( url ) {

    const arr = images;
    arr.push( url );
    setImages( arr );
    reloadDummy();

  }

  function addVideo( video, preview ) {

    setVideo( video );
    setVideoPreview( preview );
    // reloadDummy();

  }

  async function sendData() {

    try {

      if ( name === "" ) { throw("Заполните имя отправителя отзыва!"); }
      if ( review === "" ) { throw("Заполните текст отзыва!"); }

      const popupData = {

        author: name,
        text: review,
        images: images,
        video: video,
        videoPreview: videoPreview,

      }

      site.addReview( popupData );

      clearFields();
      hidePopup();

    } catch ( err ) {

      alert( err );

    }

  }

  const CONTENT = Popup({

    title: "Добавление \n отзыва",
    withoutPadding: true,
    action: sendData,

    content: (

      <div className="flex">

        <div className="popup__content popup__review">

          <TextField
          
            type="text"
            title="Имя:"
            value={ name }
            set = { setName }
            
          />

          <TextField
          
            type="text"
            title="Текст отзыва:"
            isArea={ true }
            value={ review }
            set = { setReview }

          />

          <Dummy />

          <div className="images flex justify-start">

            { images.length > 0 && images.map(( el, key ) => (

              <img key = { key } src = { el } />

            ))}

            { videoPreview !== "" && <img src = { videoPreview } /> }

            { images.length <= 2 && 
            
              <ImageLoadBtn setUrl = { addImage } />
              
            }
            
            { videoPreview === "" &&
            
              <VideoLoadBtn setUrl = { addVideo } />
              
            }

          </div>

        </div>

      </div>

    )

  });

	return [

    CONTENT,
    showPopup,
    hidePopup

  ]

}

export default SiteReviewPopUp;

const ImageLoadBtn = ({ setUrl }) => {
  
  const [ progress, setProgress ] = useState( 0 );
  
  return (

    progress === 0 

      ? <ImageLoader 

          setProgress = { setProgress }
          setUrl = { setUrl }

        >

          <div className="imageload flex items-center justify-center pointer">

            <ImageEmpty className = "imageload__image" />

          </div>

        </ImageLoader>

      : <div className = "imageload">

          <div className = "imageload__progress" style = {{ width: `${ progress }%` }} />

        </div>

  );

}

const VideoLoadBtn = ({ setUrl }) => {
  
  const [ progress, setProgress ] = useState( 0 );
  
  return (

    progress === 0 

      ? <VideoLoader

          setProgress = { setProgress }
          setUrl = { setUrl }

        >

          <div className="imageload flex items-center justify-center pointer">

            <VideoEmpty className = "imageload__image" />

          </div>

        </VideoLoader>

      : <div className = "imageload">

          <div className = "imageload__progress" style = {{ width: `${ progress }%` }} />

        </div>

  );

}
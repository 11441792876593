import { get, post } from 'axios';
import cookie from 'react-cookies';
import { API } from '../../settings/api';

export async function getAllData( store, reload ) {

  try {

    const token = cookie.load(`token`);
    const { status, data } = await post( API.site.getAllData, {}, { headers: { token } } );

    console.log(`companies.All data:`, data);

    if ( status !== 200 || !data.success ) { throw( data.text ) }

    store.setState({

      reviews: data.reviews,
      portfolio: data.portfolio,
      main_text: data.main_text,

    });

    reload();

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }
  
}

export async function addReview( store, form ) {

  try {

    const token = cookie.load(`token`);
    const { status, data } = await post( API.site.reviews.add, form, { headers: { token } } );

    console.log(`companies.All data:`, data);

    if ( status === 200 ) {

      if ( data.success ) {

        const reviews = [];

        reviews.push({

          id: data.id,
          author: form.author,
          text: form.text,
          images: form.images

        });

        for ( let i = 0; i < store.state.reviews.length; i++ ) {

          reviews.push( store.state.reviews[ i ] );
          console.log(`reviews.push from store`, store.state.reviews[ i ]  );

        }

        store.setState({

          reviews: reviews

        });

        // reload();
        
      } else {

        throw( data.text );

      }

    } else {

      throw('Нет соединения с сервером');

    }

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }
  
}

export async function chageMainText( store, form ) {

  try {

    const token = cookie.load(`token`);
    const { status, data } = await post( API.site.chageMainText, form, { headers: { token } } );

    if ( !data.success ) { throw( data.text ); }

    store.setState({ main_text: form.text });

    // reload();

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }
  
}

export async function removeReview( store, id ) {

  try {
  
    if ( !window.confirm("Вы действительно хотите удалить отзыв? Это действие отменить нельзя.") ){
    
      return;
    
    }

    const token = cookie.load("token");
    const { data } = await post( API.site.reviews.remove, { id: id }, { headers: { token: token }} );

    if ( !data.success ){ throw("не удалось удалить файл") };

    const updated = store.state.reviews.filter( el => el.id !== id );

    store.setState({ reviews: updated });
  
  } catch( err ){
  
    console.error( err );
  
  }

}

export async function addPortfolio( store, url ) {

  try {
  
    const token = cookie.load("token");
    const { data } = await post( API.site.portfolio.add, { url: url }, { headers: { token: token }} );

    if ( !data.success ){ throw("не удалось загрузить файл") }

    const cases = store.state.portfolio;

    cases.push({

      id: data.id,
      image: url

    });

    store.setState({ portfolio: cases })
  
  } catch( err ){
  
    console.error( err );
  
  }

}

export async function removePortfolio( store, id ) {

  try {
  
    if ( !window.confirm("Вы действительно хотите удалить файл? Это действие отменить нельзя.") ){
    
      return;
    
    }

    const token = cookie.load("token");
    const { data } = await post( API.site.portfolio.remove, { id: id }, { headers: { token: token }} );

    if ( !data.success ){ throw("не удалось удалить файл") };

    const cases = store.state.portfolio.filter( el => el.id !== id );

    store.setState({ portfolio: cases });
  
  } catch( err ){
  
    console.error( err );
  
  }

}
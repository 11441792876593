import axios from 'axios';
import cookie from 'react-cookies';
import textToTranslit from '../../scripts/helpers/text.to.translit';
import { HOST } from '../../settings/host';

const upload = async ( store, e, files, setProgress ) => {

  try {

    const token = cookie.load(`token`);
    const uniq = Math.trunc( Math.random( 0, 1 ) * 1000 );

    e.preventDefault();

    let fd = new FormData();

    for ( let file of files ) {

      let _file = file;
      console.log( file );
      fd.append('file', _file, `${ uniq }_${ textToTranslit( file.name ) }` );
      
    }

    const response = await axios.post(`${ HOST }/image/load`, fd, {

      headers: { 
        'Content-Type': 'multipart/form-data',
        'token': token
      },
      onUploadProgress: (progressEvent) => {

        const { loaded, total } = progressEvent;
        let percent = Math.floor( (loaded * 100) / total );
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        percent <= 100 && setProgress(percent);                

      }

    });

    if ( !response.data.success || !response.data.url ) { throw("Сервер не смог обработать файл"); }
    
    setTimeout(() => setProgress(0), 200);

    return response.data.url;
   

  } catch(err) {

    console.error( err );

    setTimeout(() => setProgress(0), 200);

    return false;

  }

}

export { 

  upload

}
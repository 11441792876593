import React from "react";

const DateChoice = ({
  
  selectedDate,
  monthNames,
  getPrevMonth,
  getNextMonth,
  getPrevYear,
  getNextYear

}) => {

  return (

    <div className="calendar-panel-btn">

      <div>

        <div className = "calendar-panel-btn__arrow" onClick={getPrevYear} title="Предыдущий год">

          <svg
            
            width="5"
            height="10"
            viewBox="0 0 5 10"
            fill="#bde7ff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.27703e-05 4.604L4.60395 9.20794V4.604V7.24203e-05L2.27703e-05 4.604Z" />
          </svg>

        </div>

        { selectedDate.getFullYear() }

        <div className = "calendar-panel-btn__arrow" onClick={getNextYear} title="Следующий год">
            
          <svg
          
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="#bde7ff"
            xmlns="http://www.w3.org/2000/svg"
            
          >
            <path d="M5.11985 4.604L0.515918 7.16441e-05L0.515918 4.72188L0.51592 9.20793L5.11985 4.604Z" />
          </svg>

        </div>

      </div>

      <div>

        <div className = "calendar-panel-btn__arrow" onClick={getPrevMonth} title="Предыдущий месяц">

          <svg
            
            width="5"
            height="10"
            viewBox="0 0 5 10"
            fill="#bde7ff"
            xmlns="http://www.w3.org/2000/svg"

          >
            <path d="M2.27703e-05 4.604L4.60395 9.20794V4.604V7.24203e-05L2.27703e-05 4.604Z" />
          </svg>

        </div>

        <div className="calendar-panel__monthname">{ monthNames[ selectedDate.getMonth() ] }</div>

        <div className = "calendar-panel-btn__arrow" onClick={getNextMonth} title="Следующий месяц">

          <svg
            
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="#bde7ff"
            xmlns="http://www.w3.org/2000/svg"

          >
            <path d="M5.11985 4.604L0.515918 7.16441e-05L0.515918 4.72188L0.51592 9.20793L5.11985 4.604Z" />
          </svg>

        </div>

      </div>

    </div>

  );

};

export default DateChoice;

import React, { Fragment, useState } from 'react';
import PopUp from '../container';

import TextField from "../../components/textfield";

import "./company_material.scss";


const CompanyMaterialPopUp = () => {

  const [ Popup, showPopup, hidePopup ] = PopUp();

  const [article, setArticle] = useState("");
  const [price, setPrice] = useState("");

  function clearFields() {

    setArticle("");
    setPrice("");

  }

  function sendData () {

    const popupData = {

      article: article,
      price: price,

    }

    console.log('sendData', popupData );

    clearFields();
    hidePopup();

  }

  const CONTENT = Popup({

    title: "Добавление типа материала",
    withoutPadding: true,
    action: sendData,

    content: (

      <div className="flex">

        <div className="popup__content">

          <TextField
          
            type="text"
            title="Название:"
            value={ article }
            set = { setArticle }
            
          />

          <TextField
          
            type="text"
            title="Цена по умолчанию:"
            value={ price }
            set = { setPrice }
            
          />

          </div>

        </div>

    )

  });

	return [

    CONTENT,
    showPopup,
    hidePopup

  ]

}


export default CompanyMaterialPopUp;
import * as sign from './sign';
import * as companies from './companies';
import * as site from './site';
import * as images from './images';
import * as videos from './videos';

async function changeStates( store, states ) {

  store.setState( states );

}

export {

  changeStates,
  sign,
  companies,
  site,
  images,
  videos

}
import React, { useEffect } from 'react';

import { ReactComponent as Trash } from './trash.svg';
import './images.scss';

const Image = ({ remove, url = "" }) => (

  <div className="images__image relative" onClick = { remove } >

    <img src = { url } />

    <div className="images__image__blur flex items-center justify-center absolute">

      <Trash className="images__image__blur__trash" />

    </div>

  </div>

);

export default Image;
import { Fragment } from 'react';
import axios, { post } from 'axios';
import cookie from 'react-cookies';
import { RandomHash } from '../../scripts/hooks/use.reload.dummy';
import { ReactComponent as BtnRemove } from '../../styles/icons/trash.svg';
import { API } from '../../settings/api';
import { HOST } from '../../settings/host';

export async function all( store, search = "", reload = false ) {

  try {

    const token = cookie.load(`token`);
    const { status, data } = await post( API.companies.all, { search: search }, { headers: { token } } );

    console.log(`companies.All data:`, data);

    if ( status === 200 ) {

      if ( data.success ) {

        store.setState({
          companiesList: data.companies
        });

        reload();
        
      } else {

        throw( data.text );

      }

    } else {

      throw('Нет соединения с сервером');

    }

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }
  
}

export async function get( store, id, reload = false ) {

  try {

    const token = cookie.load(`token`);
    const { status, data } = await post( API.companies.get, { company_id: id }, { headers: { token } } );

    console.log(`companies.get data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    let { info, pricelist, employees } = data;

    for ( let i = 0; i < employees.length; i++ ) {

      employees[ i ].fio = (

        <Fragment>

          { employees[ i ].fio }

          <BtnRemove 

            className = "rmv_emplooye_btn"
            title = "Удалить сотрудника? Его проекты удалены не будут."
            onClick = { ( e ) => {
              
              e.stopPropagation();
              store.actions.companies.removeEmployee( employees[ i ].id ) 
            
            }} 
            
          />

        </Fragment>

      );

    }

    store.setState({

      company: {

        ...info,
        id: id

      },

      pricelist,
      employees

    });

    reload && reload();

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }
  
}

export async function getStats( store, id, reload = false ) {

  try {

    const { statsPeriod, startDate, endDate, company } = store.state;

    const params = {

      company_id: id, 
      startDate, 
      endDate,
      periodType: statsPeriod

    }

    const token = cookie.load(`token`);
    const { status, data } = await post( API.companies.getStats, params, { headers: { token } } );

    console.log(`companies.getStats data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    let { projects, profit, contracts, project_last_date } = data.stats;

    
    store.setState({

      company: {

        ...company,
        projects,
        contracts,
        allProfit: profit,
        last_date: project_last_date,

      }

    });

    reload && reload();

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }
  
}

export async function add( store, obj = {} ) {

  try {

    const token = cookie.load(`token`);

    const { status, data } = await post( API.companies.add, obj, { headers: { token } } );

    console.log(`companies.add data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    store.actions.companies.clearCompanyStates();
    window.location = "/companies";

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function remove( store, id ) {

  try {

    if ( !window.confirm(`Вы действительно хотите навсегда удалить франшизу, все её настройки, всех её сотрудников, и все её чертежи балконов? Это действие необратимо!`) ) { return; }

    const token = cookie.load(`token`);

    const { status, data } = await post( API.companies.remove, { id }, { headers: { token } } );

    console.log(`companies.remove data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    store.actions.companies.clearCompanyStates();
    window.location = "/companies";

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function changeInfo( store, id, obj = {}, reload = false ) {

  try {

    const token = cookie.load(`token`);

    const params = { 

      company_id: id,
      ...obj

    }

    const { status, data } = await post( API.companies.edit, params, { headers: { token } } );

    console.log(`companies.changeInfo data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    let newState = { 

      company: { 

        ...store.state.company,
        active: obj.active 

      } 
      
    }

    if ( obj?.company_name ) {

      newState.company = {

        ...newState.company,
        name:    obj.company_name    || "",
        city:    obj.company_city    || "",
        url:     obj.company_url     || "",
        address: obj.company_address || "",
        inn:     obj.company_inn     || "",
        ogrnip:  obj.company_ogrnip  || "",
        comment: obj.comment         || "",
        fio:     obj.owner_fio       || "",
        email:   obj.owner_email     || "",
        phone:   obj.owner_phone     || "",

      }

    }

    store.setState( newState );

    reload && reload(); //!

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }
  
}

export async function editMetric( store, id, { metric_name, metric_value = "" }, reload = false ) {

  try {

    const METRIC_NAMES = [ "yandex_metrics_key", "google_analytics_key" ];

    const METRIC_SET_DEFAULT = {

      yandex_metrics_key: store.state.company.yandex_metrics_default_key,
      google_analytics_key: store.state.company.google_analytics_default_key

    }
    
    if ( 

      !metric_name 
      || 
      metric_name === ""
      ||
      METRIC_NAMES.indexOf( metric_name) === -1
      
    ) { alert(`editMetric: недопустимое значение metric_name`); return; }

    const token = cookie.load(`token`);

    const params = { 

      company_id: id,
      metric_name,
      metric_value

    }

    const { status, data } = await post( API.companies.editMetric, params, { headers: { token } } );

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    const new_value = metric_value !== "" ? metric_value : METRIC_SET_DEFAULT[ metric_name ];

    let newState = { 

      company: { 

        ...store.state.company,
        [ metric_name ]: new_value

      } 
      
    }

    store.setState( newState );

    reload && reload();

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function changePriceList( store, data ) {

  try {

    const token = cookie.load(`token`);

    const { company } = store.state;

    const {

      pricelistType,
      targetID,
      price,
      pricePerJob,

      isMultiPrice,
      multiPriceIndex

    } = data;

    let params = {

      company_id: company.id,
      pricelist_type: pricelistType,
      target_id: targetID,
      price,
      pricePerJob,
      
      isMultiPrice,
      multiPriceIndex

    }

    const response = await post( API.companies.pricelist.edit, params, { headers: { token } } );
    console.log( `changePriceList`, response.data );

    // if ( data.success ) { reload() } //?

  } catch ( err ) {

    console.error( err );
    // showError( err );

  }

}

export async function clearCompanyStates( store ) {

  store.setState({

    company: {

      id: "",
      active: 1,
      name: "",
      city: "",
      address: "",
      inn: "",
      orgnip: "",
      reg_date: "",
      comment: "",
      email: "",
      fio: "",
      phone: "",
      employees: 0,
      projects: 0,
      contracts: 0,
      allProfit: 0,
      last_date: "",
      google_analytics_key: store.state.google_analytics_default_key || false,
      yandex_metrics_key: store.state.yandex_metrics_default_key || false,
  
    },
    pricelist: [],
    employees: [],

  });

}

export async function addEmployee( store, obj = {}, close ) {

  try {

    const token = cookie.load(`token`);

    const params = {

      company_id: store.state.company.id,
      ...obj

    }

    const { status, data } = await post( API.companies.employees.add, params, { headers: { token } } );

    console.log(`companies.addEmployee data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    store.actions.companies.get( 

      store.state.company.id, 
      () => {
        
        close();
        store.setState({ dummy: RandomHash() }) 
      
      }

    )


  } catch ( err ) {

    console.error( err );

  }
  
}

export async function editEmployee( store, obj = {}, close ) {

  try {

    const token = cookie.load(`token`);

    const params = {

      company_id: store.state.company.id,
      id: store.state.employee.id,
      ...obj

    }

    const { status, data } = await post( API.companies.employees.edit, params, { headers: { token } } );

    console.log(`companies.editEmployee data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    store.actions.companies.get( 

      store.state.company.id, 

      () => {

        store.setState({ 

          employee: {

            id: "",
            fullname: "",
            phone: "",
            email: "",
            pass: ""
        
          },          
          dummy: RandomHash()

        }) 
                
        close();

      }

    );

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function removeEmployee( store, id ) {

  try {

    if ( !window.confirm(`Вы точно хотите удалить сотрудника?`) ) { return false; }

    const token = cookie.load(`token`);

    const { status, data } = await post( API.companies.employees.remove, { id }, { headers: { token } } );

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    store.actions.companies.get( 

      store.state.company.id, 
      () => { store.setState({ dummy: RandomHash() }) }

    )

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function getCompaniesInCities( store ) {

  try {

    // alert(`getCompaniesInCities!`)
    const { data, status } = await axios.get( `${ HOST }/landing/partners` );
    console.log(`getCompaniesInCities data`, data);
    store.setState({ partners: data.result });

  } catch ( err ) {

    console.error( err );

  }
  
}


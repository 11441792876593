import React, { useEffect } from "react";
import Button from "../button";
import "./period.bar.scss";
import DatePicker from "../datepicker";
import useGlobal from "../../../store";
import cssIf from "../../../scripts/helpers/class.add.if";
import { STATS_PERIOD } from "../../../settings/constants";
import twoDigitFormat from "../../../scripts/helpers/two.digit.format";

const PeriodBar = ({ company }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { statsPeriod, startDate, endDate } = globalState;
  const { changeStates, companies } = globalActions;

  const checkIsActive = check => `${ cssIf( !check, 'un' ) }active`;

  function setPeriodMonth() {
    
    const today = new Date();

    changeStates({

      statsPeriod: STATS_PERIOD.MONTH,
      startDate: `01.${ twoDigitFormat( today.getMonth() + 1 ) }.${ today.getFullYear() }`,
      endDate: `${ twoDigitFormat( 33 - new Date(today.getFullYear(), today.getMonth(), 33).getDate() ) }.${ twoDigitFormat( today.getMonth() + 1 ) }.${ today.getFullYear() }`,

    });

  }  

  const setPeriodAll = () => changeStates({ statsPeriod: STATS_PERIOD.ALL });

  const setPeriodStartDate = date => changeStates({ startDate: date });
  const setPeriodEndDate   = date => changeStates({ endDate: date, statsPeriod: STATS_PERIOD.CUSTOM });

  useEffect(() => {

    endDate && companies.getStats( company )

  }, [ statsPeriod, endDate ]);

  return (

    <div className = "period_bar flex items-center relative">

      <Button

        text = "всё время"
        color = { checkIsActive( statsPeriod === STATS_PERIOD.ALL ) }
        action = { setPeriodAll }

      />

      <Button

        text = "месяц"
        color = { checkIsActive( statsPeriod === STATS_PERIOD.MONTH ) }
        action = { setPeriodMonth }

      />
     
      <DatePicker

        startDate = { startDate }
        endDate   = { endDate }
        setStartDate = { setPeriodStartDate }
        setEndDate   = { setPeriodEndDate }        
        isActive = { statsPeriod === STATS_PERIOD.CUSTOM }

      />

    </div>

  )

}

export default PeriodBar;
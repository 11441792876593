import React, { Fragment, useState, useEffect } from 'react';

// import useGlobal from '../../../store';
import Container from '../../components/container';

import { ReactComponent as AddReview } from './icons/add-review.svg';
import { ReactComponent as AddImage } from './icons/add-image.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';

import Image from '../../components/image';
import Button from '../../components/button';
import Review from '../../components/review';
import Accordeon from '../../components/accordeon';
import SiteReviewPopUp from '../../popups/site.review';
import SiteMaintextPopUp from '../../popups/site.maintext';
import ImageLoader from '../../components/image.loader';
import MenuMobile from '../../components/menu-mobile';

import useGlobal from '../../../store';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';

import './site.scss';

const Site = () => {

  const [ globalState, globalActions ] = useGlobal();
  const [ Dummy, reloadDummy ] = useReloadDummy();
  const { reviews, portfolio, main_text } = globalState;
  const { site } = globalActions;

  const [ siteReviewPopUp, showSiteReviewPopUp, hideSiteReviewPopUp ] = SiteReviewPopUp();
  const [ siteMaintextPopUp, showSiteMaintextPopUp, hideSiteMaintextPopUp ] = SiteMaintextPopUp();

  const [ progress, setProgress ] = useState( 0 );

  useEffect(() => {

    site.getAllData( reloadDummy );

  }, []);

  return (

    <Fragment>

      { siteReviewPopUp }
      { siteMaintextPopUp }

      <Dummy />

      <Container className = "container__site site flex flex-col">

        <MenuMobile page = { 2 } />

        <Accordeon 
        
          defaultOpened

          head = {(

            <div className="site__accordeon-head flex items-center justify-between relative">

              <div className=" flex items-center relative">

                <Arrow className="site__accordeon-head__arrow" />
                <p className="montserrat desktop">Текст оффера на первом экране</p>
                <p className="montserrat mobile">Текст оффера</p>

              </div>

              <Button

                color = "green"
                className = "site__accordeon-head__button"
                text = { <Fragment><p className="desktop">Изменить текст</p><p className="mobile">Изменить</p></Fragment> }
                action = { ( e ) => { showSiteMaintextPopUp(); e.stopPropagation(); }}

              />

            </div>

          )}

        >

          <p className="offertext montserrat">

            {( main_text && main_text !== "" ) 
              ? main_text 
              : "Запишитесь на замер и получите Шкаф, Ламинат или Линолеум - в подарок!"
            }
            
          </p>
        
        </Accordeon>

        <Accordeon 
        
          head = {(

            <div className="site__accordeon-head flex items-center justify-between relative">

              <div className=" flex items-center relative">

                <Arrow className="site__accordeon-head__arrow" />
                <p className="montserrat">Портфолио</p>

              </div>

              <ImageLoader

                setProgress = { setProgress }
                setUrl = { site.addPortfolio }

              >

                <Button

                  color = "green"
                  className = "site__accordeon-head__button pointer-events-none"
                  text = { <Fragment><p className="desktop">Добавить изображение</p><p className="mobile">Добавить</p></Fragment> }   
                  icon = { <AddImage className="site__accordeon-head__button__image" style={{ marginTop: '-1px' }} /> }  

                />

              </ImageLoader>

            </div>

          )}

        >

          <div className="site__accordeon-examples images relative">

            { portfolio.length > 0 ? portfolio.map(( el, key ) => (
            
              <Image 
              
                key = { key }
                url = { el.image }
                remove = {() => { site.removePortfolio( el.id ) }}
                
              />
            
            )) : <Fragment><br/><br/><br/><p className="nocontent montserrat">Вы ещё не добавили ни один кейс</p></Fragment> }

          </div>

        </Accordeon>

        <Accordeon 
        
          head = {(

            <div className="site__accordeon-head flex items-center justify-between relative">

              <div className=" flex items-center relative">
                  
                <Arrow className="site__accordeon-head__arrow" />
                <p className="montserrat">Отзывы</p>

              </div>

              <Button

                color = "green"
                text = { <Fragment><p className="desktop">Добавить отзыв</p><p className="mobile">Добавить</p></Fragment> }
                className = "site__accordeon-head__button"
                icon = { <AddReview className="site__accordeon-head__button__image"/> }    
                action = { ( e ) => { showSiteReviewPopUp(); e.stopPropagation(); }}

              />

            </div>

          )}

        >

          <div className="site__accordeon-reviews relative">

            { reviews.length > 0 ? reviews.map(( el, key ) => (

              <Review 
              
                key = { key }
                author = { el.author }
                text = { el.text }
                images = { el.images }
                video = { el.video }
                videoPreview = { el.videoPreview }
                remove = { () => { site.removeReview( el.id ) }}
              
              />

            )) : <Fragment><br/><br/><br/><p className="nocontent montserrat">Нет ни одного отзыва</p></Fragment>
            
            }

          </div>
                
        </Accordeon>

      </Container>

    </Fragment>

  );

}

export default Site;
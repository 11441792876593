import React from 'react';
import './button.scss';

const Button = ({ text = "Сохранить", color = "green", className = "", action = () => {}, icon = false, tip = "" }) => {

  return (
    
    <button 
    
      className={`button button--${color} ${className} flex justify-center items-center`}
      onClick={ action }
      title = { tip }
      
    >

      { icon && <div className="button__icon relative">{ icon }</div> }
        
      <p>{ text }</p>
      
    </button>

  );

}

export default Button;
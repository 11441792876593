import React from 'react';
import Button from '../button';
import './uniq.metric.scss';

const UniqMetric = ({ yandexMetricIsUniq, googleAnalyticsIsUniq, showGooglePopup, showYandexPopup, className = "" }) => {

  return (

    <section className = {`uniq-metric ${ className }`}>

      <div className = "uniq-metric__header flex justify-between">

        <p className = "montserrat">Рекламный кабинет</p>

      </div>

      <div className = "uniq-metric__body flex flex-col">

        <MetricRow

          title      = "Яндекс Метрика"
          openPopup  = { showYandexPopup }
          isUniq     = { yandexMetricIsUniq }

        />

        <div className = "uniq-metric__body__row__liner mobile"/>

        <MetricRow

          title      = "Google Analytics"
          openPopup  = { showGooglePopup }
          isUniq     = { googleAnalyticsIsUniq }

        />

      </div>

    </section>

  );

}

const MetricRow = ({ title, openPopup, isUniq }) => (

  <div className = "uniq-metric__body__row flex">

    <div className = "uniq-metric__body__row__rower flex">

      <p className = "uniq-metric__body__row__name">{ title }</p>   
      <p className = "uniq-metric__body__row__type">{ isUniq ? `личный` : `общий` }</p>

    </div>

    <Button
    
      text = { isUniq ? `редактировать` : `настроить` }
      tip   = "подключить личный рекламный кабинет"
      action = { openPopup }
      className = "uniq-metric__body__row__button relative"
      
    />   

  </div>

);

export default UniqMetric;